//router
import IndexRouters from 'router';

//scss
import 'shepherd.js/dist/css/shepherd.css';
import 'flatpickr/dist/flatpickr.css';
import 'choices.js/public/assets/styles/choices.min.css';
import './assets/scss/hope-ui.scss';
import './assets/scss/pro.scss';
import './assets/scss/custom.scss';
import './assets/scss/dark.scss';
import './assets/scss/rtl.scss';
import './assets/scss/customizer.scss';
import './assets/custom/scss/custom.scss';
import './assets/custom/scss/mobile.scss';
import './assets/modules/e-commerce/scss/e-commerce.scss';
// import './assets/scss/hope-ui-design-system/helper/utilities/_ellipsis.scss';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import LoadingPage from 'components/LoadingPage';
import { useDispatch } from 'react-redux';
import { setSetting } from './app/setting/actions';
import AuthProvider from 'providers/authProvider';

function App() {
    const dispatch = useDispatch();
    dispatch(setSetting());

    return (
        <div className="App">
            <IndexRouters />
            {/* Loading page */}
            <LoadingPage />
        </div>
    );
}

export default App;
