import { Content, Footer } from 'components/builder';
import DefaultLayoutHeader from './header';
import Sidebar from './sideBar';

export default function DefaultLayout() {
    return (
        <div>
            <Sidebar />
            <main className="main-content">
                <div className={`iq-banner default position-relative `}>
                    <DefaultLayoutHeader />
                </div>
                <Content />
                <Footer />
            </main>
        </div>
    );
}
