import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import BoxMenuPage from 'layouts/boxLayout/boxMenuPage';
import { RouteObject } from 'react-router-dom';

import Routers from 'router/const/routers';

export const menusBoxGroup: {
    path: string;
    name: string;
}[] = [
    { path: Routers.legalDocument.menu, name: Translations.legalDocument.name},
    { path: Routers.sec.menu, name: Translations.sec.menuBar},
    { path: Routers.user.menu, name: Translations.sidebar.config.account},
    { path: Routers.sor.menu, name: Translations.sidebar.safetyReport.name},
    { path: Routers.safetyInspections.menu, name: Translations.sidebar.safetyInspections.name},
    { path: Routers.equipment.menu, name: Translations.sidebar.equipment.name},
    { path: Routers.trip.menu, name: Translations.sidebar.trip.name},
    { path: Routers.kpi.menu, name: Translations.sidebar.kpi.name},
    { path: Routers.wpr.menu, name: Translations.sidebar.wpr.name},
    { path: Routers.trainingOnline.menu, name: Translations.sidebar.trainingOnline.name},
    { path: Routers.riskAssessment.menu, name: Translations.sidebar.riskAssessment.name},
    { path: Routers.incidentReport.menu, name: Translations.sidebar.incidentReport.name},
    { path: Routers.message.menu, name: Translations.sidebar.message.name},
    // { path: Routers.action.menu, name: 'Action' },
    { path: Routers.library.menu, name: Translations.sidebar.library.name},
    { path: Routers.customer.menu, name: Translations.pageName.customer},
    { path: Routers.config.menu, name: Translations.sidebar.config.name},
    { path: Routers.hotline.menu, name: 'Hotline' },
    // { path: Routers.jmp.menu, name: 'JMP' },
];

const menuRouters: RouteObject[] = menusBoxGroup.map((n) => ({
    path: n.path,
    element: <BoxMenuPage />,
}));

export default menuRouters;
