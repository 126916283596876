import { memo, useEffect, useState } from 'react';

//react-bootstrap
import { Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';

//router
import { Link, Router, useLocation } from 'react-router-dom';

//component
import Card from 'components/shared/Card';
import { CustomToggle, SelectLanguage } from 'components/shared';

//img

// logo
import { Logo } from 'components/shared';

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

import { setShowCustomerModal } from 'app/appSlice';
import { ZoomIn, ZoomOut } from 'assets/icons';
import { ArrowRight } from 'assets/icons/outlined';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';
import { fullscreen, minisidebar, hidenMiniSideBar } from 'components/builder/actions';
import { useAuth } from 'providers/contexts';
import { isMobileDevide } from 'helper';
import { findRouteByCurrentPath } from 'router/const/pageInfos';
import { lang } from 'i18n/lang';
import { Person, PersonCircle } from 'react-bootstrap-icons';
import UserProfileActions from 'layouts/component/userProfileActions';
import useLayout, { LAYOUT_TYPE } from 'hooks/useLayout';

const DefaultLayoutHeader = memo(() => {
    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const themeFontSize = useSelector(SettingSelector.theme_font_size);
    const headerNavbar = useSelector(SettingSelector.header_navbar);
    const [currentLayout, setCurrentLayout] = useLayout();
    const { tokenProfile } = useAuth();
    // set hide menu vertical sizebar if is mobile
    useEffect(() => {
        if (isMobileDevide()) {
            hidenMiniSideBar();
        }
    }, []);


    useEffect(() => {
        if (headerNavbar === 'navs-sticky' || headerNavbar === 'nav-glass') {
            window.onscroll = () => {
                if (document.documentElement.scrollTop > 50) {
                    document.getElementsByTagName('nav')[0].classList.add('menu-sticky');
                } else {
                    document.getElementsByTagName('nav')[0].classList.remove('menu-sticky');
                }
            };
        }

        document.getElementsByTagName('html')[0].classList.add(themeFontSize);

        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });

    const [show, setShow] = useState(true);

    const [show1, setShow1] = useState(false);

    let location = useLocation();

    const dispatch = useDispatch();
    // set current page name
    const pageInfo = findRouteByCurrentPath();
    // const pageInfoText = pageInfos.filter((p) => {
    //     return location.pathname.startsWith(p.path) && p.path !== Routers.root;
    // });

    // logout hook
    const { logOut } = useAuth();

    return (
        <Navbar expand="xl" className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(' ')}`}>
            <Container fluid className="navbar-inner">
                {/* Logo */}
                <Link to={Routers.root} className="navbar-brand">
                    <Logo />
                    <h4 className="logo-title ms-3 mb-0">HSEVN</h4>
                </Link>

                <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                    <ArrowRight />
                </div>

                <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
                    <small className="mb-0 text-capitalize text-primary">
                        {lang.text(pageInfo?.groupName ?? '')} {pageInfo?.groupName ? ' > ' : ''}
                    </small>
                    <Link to={pageInfo?.path ?? '#'}>
                        <small className="mb-0 text-capitalize">{lang.text(pageInfo?.title ?? '')}</small>
                    </Link>
                    <div className={`offcanvas-backdrop fade  ${show1 === true ? 'show d-block' : 'd-none'}`} onClick={() => setShow1(false)}></div>
                </div>

                {/* Toggle mobile nav */}
                <div className="d-flex align-items-center">
                    <Button
                        id="navbar-toggle"
                        bsPrefix="navbar-toggler"
                        type="button"
                        aria-expanded={show1}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        onClick={() => setShow1(!show1)}
                    >
                        <span className="navbar-toggler-icon">
                            <span className="mt-1 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Button>
                </div>
                <UserProfileActions />
            </Container>
        </Navbar>
    );
});

DefaultLayoutHeader.displayName = 'DefaultLayoutHeader';
export default DefaultLayoutHeader;
