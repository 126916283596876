//react-bootstrap
import { Button, Col, Row, Spinner } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Save } from 'assets/icons/outlined';
import { InputField, SelectField, TextAreaField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { Suspense, lazy, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { RiskLevelResponse, RiskLevelUpdateRequest } from 'api/models/organization';
import { PaginationBase } from 'api/models/common';
import { SaveButton } from 'components/Button';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

const ConfigRiskLevelUpdatePage = memo(() => {
    const { t } = useTranslation();
    const params = useParams();

    const schema = yup.object().shape({
        fieldNameVn: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        note: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue,
    } = useForm<{ fieldNameVn: string; note?: string }>({
        defaultValues: {
            fieldNameVn: '',
            note: '',
        },
        resolver: yupResolver(schema),
    });

    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState<RiskLevelUpdateRequest>({
        fieldNameVn: '',
        note: '',
        id: '',
    });

    useEffect(() => {
        params.id &&
            riskLevelApi
                .getById(params.id)
                .then((res: RiskLevelResponse) => {
                    setValue('fieldNameVn', res.fieldNameVn);
                    setValue('note', res.note);
                    setData(res);
                })
                .catch((n) => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUpdate = () => {
        const values = getValues();

        const _updateData: RiskLevelUpdateRequest = {
            id: params.id ?? '',
            fieldNameVn: values.fieldNameVn,
            note: values.note,
        };

        riskLevelApi
            .update(_updateData)
            .then(() => {})
            .catch((n) => {});
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="text-end w-100 d-flex justify-content-between align-items-center"></div>
                </Card.Header>
                <Card.Body>
                    <form className="row justify-content-center" onSubmit={handleSubmit(onUpdate)}>
                        <Row>
                            <InputField md={12} xs={12} name="fieldNameVn" control={control} label={t(Translations.form.name)} placeholder="" />
                            <TextAreaField md={12} xs={12} name="note" control={control} label={t(Translations.form.content)} placeholder="" />
                            <Col md={12} xs={12}>
                                <div className="d-flex justify-content-end">
                                    <SaveButton type="submit" />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigRiskLevelUpdatePage;
