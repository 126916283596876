import { SelectField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useCustomer } from 'providers/contexts';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { BaseFormControlProps } from 'components/FormControl/baseFormProps';
import configContractorApi from 'api/v2/configContractorApi';

export interface ContractorSelectProps extends BaseFormControlProps {
    onValueChange?: (id: string) => void;
    isAddAll?: boolean;
    control: Control<any>;
}
export function ContractorSelect({ xs, name = 'contractorId', control, required, md = 4, onValueChange, isAddAll, readOnly }: ContractorSelectProps) {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        loadContractors();
    }, []);
    const loadContractors = () => {
        configContractorApi
            .getByCurrentCustomer({
                currentPage: 1,
                rowsPerPage: 1000,
            })
            .then((n) => {
                let newOptions = n.datas?.toSelectOptions('contractorName', 'id');
                setOptions(newOptions ?? []);
            })
            .catch((n) => {});
    };

    return (
        <SelectField
            xs={xs}
            readOnly={readOnly}
            label={t(Translations.contractor)}
            options={options}
            name={name ?? 'contractorId'}
            control={control}
            required={required}
            isMutiple={false}
            md={md}
            isAddAll={isAddAll}
            onValueChange={(e) => {
                onValueChange && onValueChange(e);
            }}
        />
    );
}
export default ContractorSelect;
