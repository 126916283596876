import { yupResolver } from '@hookform/resolvers/yup';
import iirReportApi from 'api/v2/iir/iirReportApi';
import { IIRReportRequest } from 'api/v2/iir/model/iirReport.model';
import { SaveButton, UpdateButton } from 'components/Button';
import { InputField, TextAreaField } from 'components/FormControl';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import IIRCategoryIncidentLevelSelect, { IIRBaseCategorySelect } from '../category/component/iirCategoryIncidentLevelSelect.component';
import IIRCategoryIncidentTypeSelect from '../category/component/iirCategoryIncidentTypeSelect.Component';
import DateTimeField from 'components/FormControl/DateTimeField';
import IIREquipmentViewComponent from './component/iirEquipment.component';
import IIRPersonViewComponent from './component/iirPerson.component';
import IIREnvironmentalDamageViewComponent from './component/iirEnvironmentalDamage.component';
import IIRTextListViewComponent from './component/iirTextListView.component';
import OrganizationUnitMultiSelect from 'components/Common/OrganizationUnitMultiSelect';
import IIRActionFollowUpViewComponent from './component/iirActionFollowUp.component';
import UserSelectField from 'components/Common/UserSelect';
import { AreaSelect, CompanySelect } from 'components/Common';
import IIRRootCauseFormComponent from './component/iirIndirectCause.component';
import {
    IIRActionFollowUpModel,
    IIRActionFollowUpModelSchema,
    IIRCauseGroupModel,
    IIREnvironmentalDamageModel,
    IIREnvironmentalDamageModelSchema,
    IIREquipmentReportModel,
    IIREquipmentReportModelSchema,
    IIREvidencesModel,
    IIREvidencesModelSchema,
    IIRPersonModel,
    IIRPersonModelSchema,
    IIRSequenOfEventModel,
    IIRSequenOfEventModelSchema,
} from 'api/v2/iir/model/iirReportComponent.model';
import { Gender, IIRCausesType, IIRUserType } from 'api/enums/IIR';
import IIRUserComponent from './component/iirUser.component';
import IIRUserSelect from './component/select/iirInvestigatorSelect.component';
import IIREvidentViewComponent from './component/iirEvident.component';
import IIRSequenOfEventViewComponent from './component/iirSequenOfEvent.component';
import { stringArrayValidation } from 'helper/yup.common.validation';
import { object, string, array } from 'yup';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
export interface IIRReportLocalRequest extends IIRReportRequest {
    /**
     * DIRECT CAUSES - Nguyên nhân trực tiếp
     */
    directCausesObjects?: { name: string }[];

    /**
     * Lessons learnT / Bài học rút kinh nghiệm
     */
    lessonsObjects?: { name: string }[];

    /**
     * Other comments / Các ý kiến khác .
     */
    otherCommentsObjects?: { name: string }[];
    causeJobFactorIds?: string[];
    causePersonalFactorIds?: string[];
    causeUnsafeActionIds?: string[];
    causeUnsafeConditionIds?: string[];
}
const defaultReportData: IIRReportLocalRequest = {
    directCauses: [],
    lessons: [],
    otherComments: [],
    id: '',
    reportName: '',
    unitMoney: '',
    categoryIncidentTypeId: '',
    categoryLevelId: '',
    companyId: '',
    areaId: '',
    incidentTime: new Date(),
    persons: [],
    equipments: [],
    workEnvironment: '',
    workActivities: '',
    involvedFactors: '',
    description: '',
    sequenOfEvents: [],
    evidences: [],
    directCausesObjects: [],
    causeIds: [],
    lessonsObjects: [],
    actionFollowUps: [],
    otherCommentsObjects: [],
    investigatorIds: [],
    bossIds: [],
    environmentalDamages: [],
    /// Nguyên nhan gốc hoặc gián tiếp
    causeJobFactorIds: [],
    causePersonalFactorIds: [],
    causeUnsafeActionIds: [],
    causeUnsafeConditionIds: [],
};

const IIRReportCreationPage: React.FC = () => {
    // const [causesIds, setCausesIds] = useState<{
    //     jobFactors: string[];
    //     personalFactors: string[];
    //     unsafeAction: string[];
    //     unsafeCondition: string[];
    // }>({
    //     jobFactors: [],
    //     personalFactors: [],
    //     unsafeAction: [],
    //     unsafeCondition: [],
    // });
    const { id } = useParams();
    const iirReportCreateSchema = yup.object().shape({
        id: yup.string().optional(),
        reportName: yup.string().required('Tên báo cáo không được để trống'),
        unitMoney: yup.string().required('Đơn vị tiền tệ không được để trống'),
        categoryIncidentTypeId: yup.string().required('Loại sự cố không được để trống'),
        categoryLevelId: yup.string().required('Cấp độ điều tra không được để trống'),
        companyId: yup.string().required('Nhà máy không được để trống'),
        areaId: yup.string().optional(),
        incidentTime: yup.date().required('Thời gian xảy ra sự cố không được để trống'),
        persons: IIRPersonModelSchema,
        equipments: IIREquipmentReportModelSchema,
        workEnvironment: yup.string().required('Môi trường làm việc không được để trống'),
        workActivities: yup.string().required('Công việc thực hiện khi xảy ra sự cố không được để trống'),
        involvedFactors: yup.string().required(''),
        description: yup.string().required('Mô tả sự cố không được để trống'),
        sequenOfEvents: IIRSequenOfEventModelSchema,
        evidences: IIREvidencesModelSchema,
        directCauses: stringArrayValidation,
        causeIds: stringArrayValidation,
        lessons: stringArrayValidation,
        actionFollowUps: IIRActionFollowUpModelSchema,
        otherComments: stringArrayValidation,
        investigatorIds: stringArrayValidation,
        bossIds: yup.array().of(yup.string().required()).required('Lãnh đạo không được để trống'), // Ensure at least one bossId is present

        environmentalDamages: IIREnvironmentalDamageModelSchema,
        directCausesObjects: yup.array().of(yup.object().shape({ name: yup.string().required() })),
        lessonsObjects: yup.array().of(yup.object().shape({ name: yup.string().required() })),
        otherCommentsObjects: yup.array().of(yup.object().shape({ name: yup.string().required() })),
    });

    const [rootCauses, setRootCauses] = useState<IIRCauseGroupModel[]>([]);
    const [indirectCauses, setIndirectCauses] = useState<IIRCauseGroupModel[]>([]);
    // useEffect(() => {
    //     let newCauseIds = [...causesIds.jobFactors, ...causesIds.personalFactors, ...causesIds.unsafeAction, ...causesIds.unsafeCondition];
    //     setValue('causeIds', newCauseIds);
    // }, []);

    useEffect(() => {
        iirReportApi.getAllCauseGroups().then((res) => {
            setRootCauses(res.filter((n) => n.type === IIRCausesType.JobFactors || n.type === IIRCausesType.PersonalFactors));
            setIndirectCauses(res.filter((n) => n.type === IIRCausesType.UnsafeAction || n.type === IIRCausesType.UnsafeCondition));
        });
        if (id) {
            iirReportApi
                .getById(id)
                .then((res) => {
                    var cause = {
                        jobFactors: res.causeGroups.filter((r) => r.type === IIRCausesType.JobFactors).flatMap((n) => n.causes?.map((c) => c.id) ?? []),
                        personalFactors: res.causeGroups.filter((r) => r.type === IIRCausesType.PersonalFactors).flatMap((n) => n.causes?.map((c) => c.id) ?? []),
                        unsafeAction: res.causeGroups.filter((r) => r.type === IIRCausesType.UnsafeAction).flatMap((n) => n.causes?.map((c) => c.id) ?? []),
                        unsafeCondition: res.causeGroups.filter((r) => r.type === IIRCausesType.UnsafeCondition).flatMap((n) => n.causes?.map((c) => c.id) ?? []),
                    };
                    reset({
                        ...res,
                        id: id,
                        otherCommentsObjects: res.otherComments.map((n) => ({ name: n })),
                        lessonsObjects: res.lessons.map((n) => ({ name: n })),
                        directCausesObjects: res.directCauses.map((n) => ({ name: n })),
                        bossIds: res.boss.map((n) => n.userId),
                        investigatorIds: res.investigators.map((n) => n.userId),
                        // causeIds: res.causeGroups.map((n) => n.id),
                        causeJobFactorIds: cause.jobFactors,
                        causePersonalFactorIds: cause.personalFactors,
                        causeUnsafeActionIds: cause.unsafeAction,
                        causeUnsafeConditionIds: cause.unsafeCondition,
                    });
                })
                .catch((n) => {});
        }
    }, []);

    const [isReadOnly, seIsReadOnly] = useState<boolean>(false);
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        setValue,
        register,
        formState: { isSubmitting, errors },
    } = useForm<IIRReportLocalRequest>({
        resolver: yupResolver(iirReportCreateSchema),
        shouldFocusError: true,
        defaultValues: defaultReportData,
    });

    const validationForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let er: string[] = [];
        const values = getValues();
        values.causeIds = [...(values.causeJobFactorIds ?? []), ...(values.causePersonalFactorIds ?? []), ...(values.causeUnsafeActionIds ?? []), ...(values.causeUnsafeConditionIds ?? [])];
        if (!values.reportName) {
            er.push('Tên báo cáo không được để trống');
        }
        if (!values.unitMoney) {
            er.push('Đơn vị tiền tệ không được để trống');
        }
        if (!values.categoryIncidentTypeId) {
            er.push('Loại sự cố không được để trống');
        }
        if (!values.categoryLevelId) {
            er.push('Cấp độ điều tra không được để trống');
        }
        if (!values.companyId) {
            er.push('Nhà máy không được để trống');
        }
        if (!values.incidentTime) {
            er.push('Thời gian xảy ra sự cố không được để trống');
        }

        if (!values.persons.hasValue() && !values.equipments.hasValue()) {
            er.push('Thông tin con người hoặc thông tin thiết bị không được để trống');
        }

        if (!values.workEnvironment) {
            er.push('Môi trường làm việc không được để trống');
        }
        if (!values.workActivities) {
            er.push('Công việc thực hiện khi xảy ra sự cố không được để trống');
        }
        if (!values.description) {
            er.push('Mô tả sự cố không được để trống');
        }
        if (!values.bossIds || values.bossIds.length === 0) {
            er.push('Lãnh đạo không được để trống');
        }
        if (!values.investigatorIds || values.investigatorIds.length === 0) {
            er.push('Điều tra viên không được để trống');
        }
        if (!values.sequenOfEvents.hasValue()) {
            er.push('Trình tự diễn biến không được để trống');
        }
        if (!values.directCauses) {
            er.push('Thông tin con người hoặc thông tin thiết bị không được để trống');
        }

        if (er.length > 0) {
            toast.error(er.join(', '));
            console.log(er);
            return false;
        }

        onSubmit(values);
    };
    const onSubmit = async (values: IIRReportLocalRequest) => {
        var payload: IIRReportRequest = {
            ...values,
            id: id,
        };
        if (!id)
            iirReportApi
                .create(payload)
                .then(() => {
                    reset(defaultReportData);
                })
                .catch((n) => {});
        else
            iirReportApi
                .update(payload)
                .then(() => {})
                .catch((n) => {});
    };

    const equipmentsFieldArray = useFieldArray({
        control,
        name: 'equipments',
    });

    const personsFieldArray = useFieldArray({
        control,
        name: 'persons',
    });

    const evironmentDamagesFieldArray = useFieldArray({
        control,
        name: 'environmentalDamages',
    });

    const otherCommentsFieldArray = useFieldArray({
        control,
        name: 'otherCommentsObjects',
    });

    const directCausesFieldArray = useFieldArray({
        control,
        name: 'directCausesObjects',
    });

    const lessonsFieldArray = useFieldArray({
        control,
        name: 'lessonsObjects',
    });

    const evidentFieldArray = useFieldArray({
        control,
        name: 'evidences',
    });
    const actionFollowUpsFieldArray = useFieldArray({
        control,
        name: 'actionFollowUps',
    });
    const sequenOfEventFieldArray = useFieldArray({
        control,
        name: 'sequenOfEvents',
    });

    useEffect(() => {
        setValue(
            'lessons',
            lessonsFieldArray.fields?.map((n: any) => n.name)
        );
    }, [lessonsFieldArray]);

    useEffect(() => {
        setValue(
            'directCauses',
            directCausesFieldArray.fields?.map((n: any) => n.name)
        );
    }, [directCausesFieldArray]);

    useEffect(() => {
        setValue(
            'otherComments',
            otherCommentsFieldArray.fields?.map((n: any) => n.name)
        );
    }, [otherCommentsFieldArray]);
    return (
        <div>
            <form onSubmit={validationForm}>
                {/* handleSubmit(onSubmit, (e) => {
                console.log(getValues());
                console.log(e);
            }) */}
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between">
                            <h5>{lang.text(Translations.common.generalInfo)}</h5>
                            <div className="d-flex ">
                                {!isReadOnly && <SaveButton type="submit" />}
                                <UpdateButton onClick={() => seIsReadOnly(false)} className="mx-2" isRender={isReadOnly} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <InputField md={6} required name="reportName" control={control} label={lang.text(Translations.iir.reportName)} readOnly={isReadOnly} />
                            <DateTimeField required label={lang.text(Translations.iir.incidentTime)} md={3} name={'incidentTime'} control={control} readOnly={isReadOnly} isShowTimePicker={true} />
                            <InputField required md={3} control={control} name="unitMoney" label={lang.text(Translations.iir.unitMoney)} />

                            <CompanySelect required={true} md={3} name="companyId" control={control} />
                            <AreaSelect companyId={watch('companyId')} md={3} name="areaId" control={control} />
                            <IIRCategoryIncidentTypeSelect required control={control} name={'categoryLevelId'} md={3} companyId={watch('companyId')} />
                            <IIRCategoryIncidentLevelSelect required control={control} name={'categoryIncidentTypeId'} md={3} companyId={watch('companyId')} />
                        </Row>
                    </Card.Body>
                </Card>
                <IIRPersonViewComponent datas={personsFieldArray?.fields} arrayForm={personsFieldArray} />
                <IIREquipmentViewComponent datas={equipmentsFieldArray?.fields} arrayForm={equipmentsFieldArray} />
                <IIREnvironmentalDamageViewComponent datas={evironmentDamagesFieldArray?.fields} arrayForm={evironmentDamagesFieldArray} />
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.workEnvironment)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <TextAreaField md={6} name={'workEnvironment'} control={control} readOnly={isReadOnly} />
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.workActivities)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <TextAreaField md={6} name={'workActivities'} control={control} readOnly={isReadOnly} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.involvedFactors)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <TextAreaField md={6} name={'involvedFactors'} control={control} readOnly={isReadOnly} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.descriptionIncident)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <TextAreaField md={6} name={'description'} control={control} readOnly={isReadOnly} />
                    </Card.Body>
                </Card>
                <IIRSequenOfEventViewComponent isReadOnly={isReadOnly} datas={sequenOfEventFieldArray?.fields} arrayForm={sequenOfEventFieldArray} />
                <IIRTextListViewComponent
                    headerColumn={lang.text(Translations.iir.directCauses)}
                    title={lang.text(Translations.iir.directCauses)}
                    datas={directCausesFieldArray?.fields?.map((d: any) => d?.name)}
                    arrayForm={directCausesFieldArray}
                />
                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.rootCauses)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <IIRRootCauseFormComponent
                                    isReadOnly={isReadOnly}
                                    name="causePersonalFactorIds"
                                    control={control}
                                    title={'Yếu tố con người'}
                                    datas={rootCauses.filter((n) => n.type == IIRCausesType.PersonalFactors)}
                                />
                            </Col>
                            <Col md={6}>
                                <IIRRootCauseFormComponent
                                    isReadOnly={isReadOnly}
                                    name="causeJobFactorIds"
                                    control={control}
                                    title={'Yếu tố công việc'}
                                    datas={rootCauses.filter((n) => n.type == IIRCausesType.JobFactors)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Header>
                        <h6 className="text-primary">{lang.text(Translations.iir.indirectCause)}</h6>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <IIRRootCauseFormComponent
                                    control={control}
                                    name="causeUnsafeActionIds"
                                    title={'Hành vi mất an toàn'}
                                    datas={indirectCauses.filter((n) => n.type == IIRCausesType.UnsafeAction)}
                                />
                            </Col>
                            <Col md={6}>
                                <IIRRootCauseFormComponent
                                    control={control}
                                    name="causeUnsafeConditionIds"
                                    title={'Điều kiện mất an toàn'}
                                    datas={indirectCauses.filter((n) => n.type == IIRCausesType.UnsafeCondition)}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <IIRTextListViewComponent
                    headerColumn={lang.text(Translations.iir.lessonsLearned)}
                    title={lang.text(Translations.iir.lessonsLearned)}
                    datas={lessonsFieldArray?.fields?.map((d: any) => d?.name)}
                    arrayForm={lessonsFieldArray}
                />
                <IIREvidentViewComponent datas={evidentFieldArray?.fields} arrayForm={evidentFieldArray} />
                <IIRActionFollowUpViewComponent datas={actionFollowUpsFieldArray?.fields} arrayForm={actionFollowUpsFieldArray} isReadOnly={isReadOnly} />
                <IIRTextListViewComponent
                    headerColumn={lang.text(Translations.iir.otherComments)}
                    title={lang.text(Translations.iir.otherComments)}
                    datas={otherCommentsFieldArray?.fields?.map((d: any) => d?.name)}
                    arrayForm={otherCommentsFieldArray}
                />
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <h6 className="text-primary">{lang.text(Translations.iir.investigator)}</h6>
                                <IIRUserSelect name="investigatorIds" control={control} type={IIRUserType.Investigator} />
                            </Col>
                            <Col md={6}>
                                <h6 className="text-primary">{lang.text(Translations.iir.boss)}</h6>
                                <IIRUserSelect {...register('bossIds', { required: true })} name="bossIds" control={control} type={IIRUserType.Boss} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </form>
            {/* Add your form elements and other components here */}
        </div>
    );
};

export default IIRReportCreationPage;
