//components
import Card from 'components/shared/Card';
import { Fragment, useEffect, useState } from 'react';
import usePromptInputText from 'components/Modal/PromptInputText';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import { ModalDetailProps } from 'models/modalDetailProps';
import { DeleteButton, DownloadButton } from 'components/Button';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { KPIReportDetailResponse } from 'api/v2/kpi/model/kpiReport.model';
import kpiReportApi from 'api/v2/kpi/kpiReportApi';
import InputFieldSimple from 'components/FormControl/InputFieldSimple';
import { formatDate } from 'helper';
import { Table } from 'react-bootstrap';
import { useAuth } from 'providers/contexts';
import { PaginationTable } from 'components/shared/PaginationTable';

const KPIReportDetailReportPage = ({ id, onDeleted }: ModalDetailProps) => {
    const [report, setReport] = useState<KPIReportDetailResponse | null>(null);
    const { openPromptInputText, PromptInputText } = usePromptInputText();
    const { userPms } = useAuth();
    useEffect(() => {
        loadReport();
    }, []);

    const loadReport = () => {
        if (id)
            kpiReportApi
                .getById(id)
                .then((r: KPIReportDetailResponse) => {
                    setReport(r);
                })
                .catch((n) => {});
    };
    const onDelete = () => {
        if (id) {
            kpiReportApi
                .delete(id)
                .then(() => onDeleted && onDeleted())
                .catch(() => {});
        }
    };
    return (
        <div>
            <Card>
                <Card.Body>
                    {report?.canDelete && (
                        <div className="row">
                            <div className="d-flex justify-content-end">
                                <ToastConfirm message={lang.text(Translations.modal.confirm.confirmDelete)} onConfirm={onDelete}>
                                    <DeleteButton isRender={report.canDelete} />
                                </ToastConfirm>
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    {report && (
                        <div className="row">
                            <InputFieldSimple md={4} label={lang.text(Translations.kpis.type)} value={report.typeName} />
                            <InputFieldSimple md={4} label={lang.text(Translations.form.site.name)} value={report.companyName} />
                            <InputFieldSimple md={4} label={lang.text(Translations.kpis.type)} value={report.typeName} />
                            <InputFieldSimple md={4} label={lang.text(Translations.kpis.reportDate)} value={formatDate(report.date)} />
                            <InputFieldSimple md={4} label={lang.text(Translations.common.createdBy)} value={report.createdUserName} />
                            <InputFieldSimple md={4} label={lang.text(Translations.common.createdTime)} value={formatDate(report.createdTime)} />
                        </div>
                    )}
                    <Table className="table table-bordered border mt-5">
                        {report &&
                            report.details.map((item, index) => (
                                <tr key={index}>
                                    <td className="p-2 border">{item.name}</td>
                                    <td className="p-2 border">
                                        <span className={item.value <= item.maximum ? 'text-success  p-0 ' : 'text-danger  p-0 '}> {item.value}</span> / {item.maximum} {item.unit}
                                    </td>
                                    <td className="p-2 border">{item.description}</td>
                                </tr>
                            ))}
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
};

export default KPIReportDetailReportPage;
