import { MenuGroupProps, AccordionItemChildProps } from 'components/builder/verticalNavGroup';
import { useAuth } from 'providers/contexts';
import { useState, useEffect } from 'react';
import { appMenus } from 'router/const/pageInfos';

const useMenu = () => {
    const { tokenProfile } = useAuth();
    const [userMenus, setUserMenus] = useState<MenuGroupProps[]>([]);
    const userRoles = tokenProfile?.roles ?? [];
    useEffect(() => {
        var menus: MenuGroupProps[] = [];
        appMenus.forEach((group) => {
            var itemsFiltered: AccordionItemChildProps[] =
                group.items
                    .filter((group) => group.isDisplayVerticalNav === true)
                    .filter((item) => {
                        if (Array.isArray(item.roles)) {
                            return tokenProfile?.isSuperAdmin === true || item.ignorePms === true || item.roles.some((role) => userRoles.includes(role));
                        } else {
                            return tokenProfile?.isSuperAdmin === true || item.ignorePms === true || userRoles.includes(item.roles);
                        }
                    }) ?? [];
            let newItems = itemsFiltered.map((n) => ({ ...n }));
            let newGroup = {
                ...group,
                items: newItems,
            };
            menus.push(newGroup);
        });
        menus = menus.filter((n) => n.items.length > 0);
        setUserMenus(menus);
    }, []);

    return { userMenus: userMenus };
};

export default useMenu;
