import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import equipmentApi from 'api/v2/equipmentApi';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { EquipmentFilterRequest, EquipmentResponse, EquipmentTypeGroupResponse } from 'api/models';
import { PaginationBase, PaginationBaseRequest, defaultPagination, defaultPaginationRequest } from 'api/models/common';
import { CompanySelect, ContractorSelect } from 'components/Common';
import { SelectField, SelectOption } from 'components/FormControl';
import { FancyTableDataType } from 'components/shared';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Routers from 'router/const/routers';
import { FilterButton } from 'components/Button/FilterButton';
import { AddButtonNavigate, DownloadButton } from 'components/Button';
import useQueryParams from 'hooks/useQueryParams';
import { PaginationTable } from 'components/shared/PaginationTable';
import {} from 'react-router-dom';
import { useAuth } from 'providers/contexts';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import EquipmentDetailPage from './detail';
import { lang } from 'i18n/lang';
import useModalHook from 'components/ModalHook';
import BoxCardItem from 'components/BoxCard';

const EquipmentListPage = () => {
    // for Show Detail equipment
    // const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);
    const [paginationData, setPaginationData] = useState<PaginationBase<FancyTableDataType>>();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    var navigate = useNavigate();
    const { userPms } = useAuth();
    const { t } = useTranslation();
    const [typeGroupOptions, setTypeGroupOptions] = useState<SelectOption[]>([]);
    const { getQueryParam } = useQueryParams();
    const { control, handleSubmit, getValues, reset, formState } = useForm({
        defaultValues: {},
        resolver: yupResolver(
            yup.object().shape({
                keySearch: yup.string().notRequired(),
                companyId: yup.string().notRequired(),
                contractorId: yup.string().notRequired(),
                typeGroupId: yup.string().notRequired(),
                areaId: yup.string().notRequired(),
                isInspected: yup.boolean().notRequired(),
            })
        ),
    });
    const location = useLocation();
    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = async (id: string) => {
        id &&
            (await openModal({
                isFullScreen: true,
                title: lang.text(Translations.equipment.detailInfo),
                isResetRoute: true,
                body: <EquipmentDetailPage id={id} />,
            }));
    };

    const onCompanyChange = (orgId: string) => {
        orgId &&
            equipmentApi
                .getTypeByCompanies([orgId])
                .then((types) => {
                    var newTypes = types.toSelectOptions('typeGroupName', 'id');
                    setTypeGroupOptions(newTypes);
                })
                .catch((e) => {});
    };

    const cols = useMemo(
        () => [
            {
                field: 'equipmentCode',
                title: t(Translations.datatable.equipment.code),
            },
            {
                field: 'companyName',
                title: t(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: t(Translations.form.area.name),
            },
            {
                field: 'contractorName',
                title: t(Translations.contractor),
            },
            {
                field: 'operatorName',
                title: t(Translations.equipment.operator),
            },
            {
                field: 'action',
                title: 'date',
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const onDelete = (id: string) => {
        return equipmentApi
            .delete(id)
            .then((n) => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((e) => {});
    };
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        let filter = {
            ...pagination,
            companyId: form.companyId,
            contractorId: form.contractorId,
            typeGroupId: form.typeGroupId,
        } as EquipmentFilterRequest;
        equipmentApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                action: (
                                    <ActionButtons2
                                        onViewDetail={(id) => {
                                            navigate('?id=' + id);
                                        }}
                                        editPms={userPms.equipment.admin}
                                        deletePms={userPms.equipment.admin}
                                        editAction={() => navigate(Routers.equipment.modify.replace(':id', n.id))}
                                        id={n.id}
                                        onDelete={(id) => onDelete(id)}
                                    />
                                ),
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };
    const onDownload = () => {
        var form = getValues();
        let filter = {
            companyId: form.companyId,
            contractorId: form.contractorId,
            typeGroupId: form.typeGroupId,
        } as EquipmentFilterRequest;
        equipmentApi
            .download(filter)
            .then((n) => {})
            .catch((n) => {});
    };
    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-100">
                            <div className="row ">
                                <CompanySelect
                                    md={4}
                                    readOnly={false}
                                    disabled={false}
                                    name="companyId"
                                    control={control}
                                    onCompanyChange={(c) => {
                                        onCompanyChange(c);
                                    }}
                                />
                                <SelectField md={4} label={t(Translations.equipment.type)} options={typeGroupOptions} name="typeGroupId" control={control} required={true} isMutiple={false} />

                                <ContractorSelect xs={12} required={false} control={control} name="contractorId" md={4} />
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-end">
                                        <DownloadButton onClick={onDownload} isRender={true} />
                                        <FilterButton type="button" className="mx-2" onClick={() => handleFetchData(defaultPaginationRequest)} />
                                        <AddButtonNavigate url={Routers.equipment.create} isRender={userPms.equipment.admin} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
                <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} wrapperClass="mt-5" />
            </div>
            {Prompt}
        </Fragment>
    );
};

export default EquipmentListPage;
