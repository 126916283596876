import Translations from 'i18n/translation';
import { useDebounce } from 'hooks';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Empty from './Empty';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import DataListView from './DataListView';
import { isMobileDevide } from 'helper';
import { lang } from 'i18n/lang';
import { CarFront } from 'react-bootstrap-icons';
import BoxCardItem from 'components/BoxCard';
import { ReposiveTable } from 'components/Table/ReposiveTable';

export type ColumnType = {
    field: string;
    title: string;
    className?: string;
    style?: CSSProperties | undefined;
};

const fancyTableDefaultPerPage = [10, 20, 30, 40, 50];

interface PaginationTableProps {
    disableSearch?: boolean;
    columns: ColumnType[];
    pagination: PaginationBase<any> | undefined;
    isStriped?: boolean;
    wrapperClass?: string;
    onSearch?: (paginate: PaginationBaseRequest) => void;
    onPaginate?: (paginate: PaginationBaseRequest) => void;
    onRowClick?: (row: any) => void;
    searchPlaceholder?: string;
    loading?: boolean;
    hideHead?: boolean;
    action?: () => React.ReactNode;
}
export function PaginationTable({
    disableSearch,
    columns,
    pagination,
    isStriped = false,
    wrapperClass = '',
    onSearch,
    onPaginate,
    onRowClick,
    loading = false,
    hideHead = false,
    searchPlaceholder = lang.text(Translations.table.find),
}: // action,
PaginationTableProps) {
    const defaultRequest = defaultPaginationRequest;
    const { t } = useTranslation();
    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRequest.rowsPerPage);
    const [columnStates, setColumnStates] = useState<ColumnType[]>(columns);
    const [currentPage, setCurrentPage] = useState<number>(defaultRequest.currentPage);
    const [keySearch, setKeySearch] = useState<string>('');
    //duration after search
    const debouncedValue = useDebounce(keySearch, 500);

    useEffect(() => {
        var lastColumn = columns[columns.length - 1];
        if (isMobileDevide() && lastColumn.field == 'action') {
            var newCols: ColumnType[] = [lastColumn, ...columns.slice(0, columns.length - 1)];
            console.log(newCols);
            setColumnStates(newCols);
        }
    }, [columns]);

    const handlePaginate = (page: PaginationBaseRequest) => {
        if (pagination?.currentPage == page.currentPage && pagination.rowsPerPage == page.rowsPerPage) return;
        setCurrentPage(page.currentPage);
        setRowsPerPage(page.rowsPerPage);

        if (pagination && onPaginate) {
            onPaginate({
                currentPage: page.currentPage,
                rowsPerPage: page.rowsPerPage,
                keySearch: keySearch,
            });
        }
    };
    useEffect(() => {
        setRowsPerPage(pagination?.rowsPerPage ?? defaultRequest.rowsPerPage);
        setCurrentPage(pagination?.currentPage ?? defaultRequest.currentPage);
    }, [pagination]);
    const handleSearchChange = (value: string) => {
        setKeySearch(value);
        onSearch &&
            onSearch({
                currentPage: currentPage,
                rowsPerPage: rowsPerPage,
                keySearch: value,
            });
    };

    const renderMainContent = () => (
        <section>
            {!hideHead && (
                <div className="flex-column gap-2 flex-md-row justify-content-between row mb-4 align-items-end">
                    {!disableSearch && (
                        <div className={`col-md-4`}>
                            {/* <Form.Label htmlFor="search">{t(Translations.table.find)}</Form.Label> */}
                            <Form.Control placeholder={searchPlaceholder} value={keySearch} onChange={(e) => handleSearchChange(e.target.value)} id="search" type="text" />
                        </div>
                    )}
                    {disableSearch && <div className={`col-md-4`}></div>}
                    {pagination && (
                        <div className="col-md-4 d-flex align-items-center gap-2 justify-content-end">
                            <span>{t(Translations.table.display)}</span>
                            <select
                                className="form-select"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    if (rowsPerPage == Number(e.target.value)) return;

                                    handlePaginate({
                                        rowsPerPage: Number(e.target.value),
                                        currentPage: 1,
                                    });
                                }}
                                style={{ width: 90 }}
                                id="perpage"
                                disabled={pagination.datas?.length ? false : true}
                            >
                                {fancyTableDefaultPerPage.map((opt, index) => (
                                    <option value={opt} key={index}>
                                        {opt}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            )}

            {/* Table body */}
            <ReposiveTable isWrapByCard={false} emptyLayout={<Empty />} pagination={pagination} columns={columnStates} datas={pagination?.datas ?? []} isStriped={isStriped} onRowClick={onRowClick} />

            <div className="row align-items-center">
                <div className="col-md-6">
                    {pagination && pagination.totalItems ? (
                        <div className="dataTables_info">
                            Showing 1 to {pagination.totalItems > pagination.rowsPerPage ? pagination.rowsPerPage : pagination.totalItems} of {pagination.totalItems} entries
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="col-md-6">
                    <div className="d-flex align-items-center justify-content-end pt-3">
                        {pagination && (
                            <ReactPaginate
                                initialPage={currentPage - 1}
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={(event: any) => {
                                    if (currentPage == event.selected + 1) return;
                                    handlePaginate({
                                        currentPage: event.selected + 1,
                                        rowsPerPage: pagination.rowsPerPage,
                                    });
                                }}
                                // forcePage={currentPage - 1}
                                onPageActive={(event: any) => {
                                    console.log('Evenmt me', event);
                                }}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={2}
                                pageCount={pagination.totalPage}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item previous"
                                previousLinkClassName="page-link previous-link"
                                nextClassName="page-item next"
                                nextLinkClassName="page-link next-link"
                                breakClassName="page-item break"
                                breakLinkClassName="page-link break-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                disabledClassName="text-danger"
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
    return isMobileDevide() ? (
        renderMainContent()
    ) : (
        <Card>
            <Card.Body>{renderMainContent()}</Card.Body>
        </Card>
    );
}
