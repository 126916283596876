import { FC, Fragment, memo, useState } from 'react';

//React-bootstrap
import { Accordion } from 'react-bootstrap';

//Components
import { ViewGrid } from 'assets/icons/double-tone';
import useMenu from 'hooks/useMenu';
import { lang } from 'i18n/lang';
import Translations from 'i18n/translation';
import { useAuth } from 'providers/contexts';
import Roles from 'router/const/roles';
import Routers from 'router/const/routers';
import SidebarMenu from './sidebarMenu';
import { AccordionGroup } from './verticalNavGroup';

const VerticalNav: FC = memo(() => {
    const [activeMenu, setActiveMenu] = useState('false');
    const [active, setActive] = useState('');
    const { tokenProfile } = useAuth();
    const userRoles = tokenProfile?.roles ?? [];

    const { userMenus } = useMenu();

    return (
        userRoles && (
            <Fragment>
                <Accordion as="ul" className="navbar-nav iq-main-menu">
                    {/* Dashboard */}
                    <SidebarMenu
                        isTag="true"
                        roles={Roles.account_management.crud}
                        pathname={Routers.dashboard.root}
                        minititle={lang.text(Translations.sidebar.dashboard)}
                        title={lang.text(Translations.sidebar.dashboard)}
                    >
                        <ViewGrid />
                    </SidebarMenu>
                    {userMenus &&
                        userMenus.map((group, index) => <AccordionGroup active={active} activeMenu={activeMenu} group={group} key={index} setActive={setActive} setActiveMenu={setActiveMenu} />)}
                </Accordion>
            </Fragment>
        )
    );
});

VerticalNav.displayName = 'VerticalNav';
export default VerticalNav;
