import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useLocation, useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import { RadioField, SelectField } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import { Badge, Col } from 'react-bootstrap';
import { ModalFullScreen } from 'components/Modal/ModalFullScreen';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import UserSelectField from 'components/Common/UserSelect';
import { lang } from 'i18n/lang';
import GoldenRuleSelect from '../component/goldenRuleSelect.component';
import RiskLevelSelect from 'components/RiskLevelSelect';
import SORCategorySelect from '../component/sorCategorySelect.component';
import { SORReportHelper } from 'helper/Feature/sor';
import sorReportApi from 'api/v2/sor/sorReportApi';
import { SORReportFilterRequest, SORReportResponse } from 'api/v2/sor/model/sorReport.model';
import DateTimeField from 'components/FormControl/DateTimeField';
import { set } from 'lodash';
import SORDetailPage from './sorDetailPage';
import useModalHook from 'components/ModalHook';
import { toast } from 'react-toastify';

const defaultToDate = new Date();
const defaultFromDate = new Date();
defaultFromDate.setDate(defaultFromDate.getDate() - 7);
// eslint-disable-next-line react-hooks/exhaustive-deps
const SORListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'companyName',
                title: lang.text(Translations.form.site.name),
            },
            {
                field: 'areaName',
                title: lang.text(Translations.form.area.name),
            },
            {
                field: 'pICFullName',
                title: lang.text(Translations.common.pic),
            },
            {
                field: 'status',
                title: lang.text(Translations.common.status),
            },
            {
                field: 'reportType',
                title: lang.text(Translations.common.reportType),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    const reportStatus = useMemo(() => SORReportHelper.getReportStatusOptions(), []);
    const location = useLocation();
    var navigate = useNavigate();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<SORReportResponse>>();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const { control, handleSubmit, getValues, reset, formState } = useForm();
    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        id && showDetail(id);
    }, [location.search]);

    const showDetail = (id: string) => {
        id &&
            sorReportApi
                .getById(id)
                .then(async (n) => {
                    await openModal({
                        isFullScreen: true,
                        title: lang.text(Translations.equipment.templateCategories),
                        isResetRoute: true,
                        body: (
                            <SORDetailPage
                                reportDefault={n}
                                onDeleted={() => {
                                    onClose && onClose();
                                    handleFetchData(defaultPaginationRequest);
                                    navigate('', { replace: true });
                                }}
                                id={id}
                            />
                        ),
                    });
                })
                .catch((n) => {});
    };
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as SORReportFilterRequest;
        filter.toDate = filter.toDate?.toEndDay();
        sorReportApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                createdTime: formatDateTime(n.createdTime),
                                status: <Badge bg={SORReportHelper.getStatusColor(n.status)}>{n.status}</Badge>,
                                reportType: <Badge bg={SORReportHelper.getReportTypeColor(n.isSafe)}>{SORReportHelper.getReportTypeText(n.isSafe)}</Badge>,
                                action: <ActionButtons2 id={n.id} onViewDetail={() => navigate('?id=' + n.id, { replace: true })} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                        <div className="row">
                            <SelectField label={t(Translations.common.status)} options={reportStatus} name="status" control={control} required={false} isMutiple={false} unSelectValue={''} md={4} />
                            <CompanySelect md={4} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                            <AreaSelect md={4} name="areaId" control={control} required={false} companyId={companyId} readOnly={false} />
                            <ContractorSelect md={4} name="contractorId" control={control} required={false} readOnly={false} />
                            <SORCategorySelect name="sORCategoryId" md={4} control={control} required={false} readOnly={false} />
                            <RiskLevelSelect name="riskLevelId" md={4} control={control} required={false} readOnly={false} />
                            <GoldenRuleSelect name="goldenRuleId" md={4} control={control} required={false} readOnly={false} />
                            <UserSelectField readOnly={false} label={t(Translations.common.pic)} name="pICId" control={control} required={false} isMutiple={false} md={4} />
                            <DateTimeField label={lang.text(Translations.common.fromDate)} md={4} control={control} readOnly={false} name={'fromDate'} defaultDate={defaultFromDate} />
                            <DateTimeField label={lang.text(Translations.common.toDate)} md={4} control={control} readOnly={false} name={'toDate'} defaultDate={defaultToDate} />{' '}
                            <Col md={4}>
                                <RadioField
                                    inline={true}
                                    label={lang.text(Translations.common.status)}
                                    control={control}
                                    name={'isSafe'}
                                    readonly={false}
                                    options={[
                                        {
                                            label: lang.text(Translations.common.safe),
                                            value: 1,
                                            defaultChecked: true,
                                            className: 'success',
                                        },
                                        {
                                            label: lang.text(Translations.common.unSafe),
                                            value: 0,
                                            className: 'danger',
                                            defaultChecked: false,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col md={4} className="filter-box">
                                <FilterButton type="submit" className="m" />
                            </Col>
                        </div>
                    </form>
                </Card.Body>
            </Card>
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={false} wrapperClass="mt-5" />
            {Prompt}
        </Fragment>
    );
};

export default SORListPage;
