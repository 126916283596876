import { useState } from 'react';
import BoxLayout from './boxLayout';
import CustomerProvider from 'providers/customerProvider';
import DefaultLayout from './defaultLayout';
import useLayout, { LAYOUT_TYPE } from 'hooks/useLayout';
const AppLayout = () => {
    const [currentLayout] = useLayout();
    return <CustomerProvider>{currentLayout === LAYOUT_TYPE.BOX ? <BoxLayout /> : <DefaultLayout />}</CustomerProvider>;
};
export default AppLayout;
