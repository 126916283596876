import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import Translations from 'i18n/translation';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationBase, Supporter } from 'api/models/common';
import { PaginationTable } from 'components/shared/PaginationTable';

const HotlinePage = memo(() => {
    const [data, setData] = useState<Supporter[]>([]);
    const selectedLanguage: string = useAppSelector(selectLanguage) as string;
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    useEffect(() => {
        setPaginationData({
            rowsPerPage: 10,
            totalItems: 2,
            totalPage: 1,
            currentPage: 1,
            datas: [
                {
                    id: '1',
                    fullName: 'Trần Đình Hoà',
                    position: 'Quản lý',
                    phoneNumber: (
                        <a href={`tel:0333655688`} rel="noreferrer" target="_blank">
                            0333655688
                        </a>
                    ),
                },
                {
                    id: '2',
                    fullName: 'Trần Hữu Ban',
                    position: 'Thiết bị / IT',
                    phoneNumber: (
                        <a href={`tel:0336252209`} rel="noreferrer" target="_blank">
                            0336252209
                        </a>
                    ),
                },
            ],
        });
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'fullName',
                title: t(Translations.datatable.fullname),
            },
            {
                field: 'position',
                title: t(Translations.datatable.authorisation),
            },
            {
                field: 'phoneNumber',
                title: t(Translations.datatable.phoneNumber),
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedLanguage]
    );

    const handleFetchData = () => {};
    return (
        <PaginationTable
            onSearch={handleFetchData}
            onPaginate={handleFetchData}
            onRowClick={(item) => {}}
            columns={cols}
            pagination={paginationData}
            isStriped={true}
            disableSearch={true}
            wrapperClass="mt-5"
        />
    );
});
export default HotlinePage;
