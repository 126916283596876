import Routers from 'router/const/routers';
import { RouteObject } from 'react-router-dom';
import {
    ConfigAreaPage,
    ConfigDepartmentPage,
    ConfigPage,
    ConfigRiskLevelPage,
    ConfigGoldenRulesPage,
    ConfigGoldenRulesCreatePage,
    ConfigGoldrenRulesEditPage,
    ConfigRiskLevelCreatePage,
    ConfigRiskLevelUpdatePage,
    ConfigCompanyPage,
    ConfigCompanyCreatePage,
    ConfigCompanyUpdatePage,
    ConfigContractorPage,
} from 'views/config';
import CustomerPage from 'views/customer';
import { UserPage } from 'views/user';

const organizationRouters: RouteObject[] = [
    {
        path: Routers.config.root,
        element: <ConfigPage />,
    },

    // Golden rules
    {
        path: Routers.config.goldenRules,
        element: <ConfigGoldenRulesPage />,
    },
    {
        path: Routers.config.goldenRulesCreate,
        element: <ConfigGoldenRulesCreatePage />,
    },
    {
        path: Routers.config.goldenRulesUpdate,
        element: <ConfigGoldrenRulesEditPage />,
    },
    {
        path: Routers.config.user,
        element: <UserPage />,
    },
    // ,
    // {
    //     path: Routers.config.accountEdit,
    //     element: <ConfigAccountEditPage />,
    // },

    // AreaRouters.config.account
    {
        path: Routers.config.area,
        element: <ConfigAreaPage />,
    },

    // Department
    {
        path: Routers.config.department,
        element: <ConfigDepartmentPage />,
    },

    // Risk level
    {
        path: Routers.config.riskLevel,
        element: <ConfigRiskLevelPage />,
    },
    {
        path: Routers.config.riskLevelCreate,
        element: <ConfigRiskLevelCreatePage />,
    },
    {
        path: Routers.config.riskLevelUpdate,
        element: <ConfigRiskLevelUpdatePage />,
    },

    // Company
    {
        path: Routers.config.company,
        element: <ConfigCompanyPage />,
    },
    {
        path: Routers.config.companyCreate,
        element: <ConfigCompanyCreatePage />,
    },
    {
        path: Routers.config.companyEdit,
        element: <ConfigCompanyUpdatePage />,
    },

    // Contractor
    {
        path: Routers.config.contractor,
        element: <ConfigContractorPage />,
    },
    // customer
    {
        path: Routers.customer.root,
        element: <CustomerPage />,
    },
];

export default organizationRouters;
