import { CSSProperties, Fragment, ReactNode, useEffect, useState } from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { PaginationBase } from 'api/models/common';
import { isMobileDevide } from 'helper';
import BoxCardItem from 'components/BoxCard';
import { ColumnType } from 'components/shared/PaginationTable';
import { toast } from 'react-toastify';

interface ReposiveTableProps {
    isWrapByCard?: boolean;
    emptyLayout?: any;
    columns: ColumnType[];
    isStriped?: boolean;
    wrapperClass?: string;
    onRowClick?: (row: any) => void;
    datas: any[];
    pagination?: PaginationBase<any>; // sỬ DỤNG RENDER STT (#) TRONG TABLE, NẾU CÓ PAGINATION
}
export function ReposiveTable({
    columns,
    isStriped = false,
    wrapperClass = '',
    onRowClick,
    datas,
    pagination,
    emptyLayout,
    isWrapByCard = true,
}: // action,
ReposiveTableProps) {
    const [columnStates, setColumnStates] = useState<ColumnType[]>(columns);
    const isDisplayBox = isMobileDevide() || (columns && columns.length > 5);
    useEffect(() => {
        var lastColumn = columns[columns.length - 1];
        if (isDisplayBox && lastColumn.field == 'action') {
            var newCols: ColumnType[] = [lastColumn, ...columns.slice(0, columns.length - 1)];
            console.log(newCols);
            setColumnStates(newCols);
        }
    }, [columns]);

    const loadMobileLayout = () => {
        return datas?.map((item, index) => {
            return <BoxCardItem data={item} fields={columnStates.map((x) => ({ propertyName: x.field, displayName: x.title }))} />;
        });
    };
    const loadTableLayout = () => {
        return (
            <div className={`fancy-table table-responsive rounded ${wrapperClass}`}>
                <Table className={`paginate-table mb-0 ${isStriped ? 'table-striped' : ''}`} id="datatable" data-toggle="data-table">
                    <thead>
                        <tr className="bg-white">
                            <th scope="col" style={{ width: '20px' }}>
                                <span>#</span>
                            </th>
                            {columnStates.map((item, index) => (
                                <th scope="col" key={index} className={`${item.className || ''} px-2`} style={{ ...item.style }}>
                                    {item.title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {datas?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {pagination && <td>{pagination.rowsPerPage * (pagination.currentPage - 1) + index + 1}</td>}
                                    {!pagination && <td>{index + 1}</td>}
                                    {columnStates.map((col, colIndex) => (
                                        <td
                                            key={colIndex + 1}
                                            className={`${col.className} ${onRowClick ?? ' cursor-pointer-hover'}}`}
                                            onClick={() => {
                                                col.field != 'action' && onRowClick && onRowClick(item);
                                            }}
                                        >
                                            {item[col.field?.trim()]}
                                        </td>
                                    ))}
                                    {/* {action && <td key={index}>{item['action']}</td>} */}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };
    const renderWrapByCardPc = () =>
        isWrapByCard ? (
            <Card>
                <Card.Body>{loadTableLayout()}</Card.Body>
            </Card>
        ) : (
            loadTableLayout()
        );

    toast.success(isWrapByCard);
    const renderMainContent = () => <section>{datas && isDisplayBox ? loadMobileLayout() : renderWrapByCardPc()}</section>;
    if (datas && datas.length > 0) return renderMainContent();
    else return <Fragment>{emptyLayout && emptyLayout}</Fragment>;
}
