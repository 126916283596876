import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { Empty, FancyTable, FancyTableDataType } from 'components/shared';
import { useForm } from 'react-hook-form';
import useModalHook from 'components/ModalHook';
import siTemplateApi from 'api/v2/safe-inspection/siTemplateApi';
import { EquipmentHelper } from 'helper/Feature/equipment.help';
import { CompanySelect } from 'components/Common';
import SIAddOrUpdateComponent from './siAddOrUpdateComponent';
import { SITemplateFilterRequest, SITemplateModel } from 'api/v2/safe-inspection/model/siTemplate';
import { SelectField } from 'components/FormControl';
import { SIReportType } from 'enum/safetyInspection';
import { ReposiveTable } from 'components/Table/ReposiveTable';

const SITemplateListPage = memo(() => {
    const reportTypes = useMemo(() => EquipmentHelper.getReportTypesOptions(), []);
    const { t } = useTranslation();
    const [companyId, setCompanyId] = useState<string | null>(null);
    const [reportType, setReportType] = useState<SIReportType>(SIReportType.Daily);
    const [sourceDatas, setSourceDatas] = useState<SITemplateModel[]>([]);
    const [datas, setDatas] = useState<FancyTableDataType[]>([]);
    const { control, ...rest } = useForm();
    const { openModal, onClose, onCompleted, Prompt } = useModalHook();

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, [companyId, reportType]);

    const handleFetchData = (pagination: PaginationBaseRequest) => {
        pagination.filter = {};
        let filter: SITemplateFilterRequest = {
            ...pagination,
            reportType: reportType,
            companyId: companyId,
        };
        siTemplateApi
            .getTemplatePagination(filter)
            .then((pagination: PaginationBase<SITemplateModel>) => {
                setSourceDatas(pagination.datas ?? []);
                const tableData = datas
                    ? pagination.datas?.map((item: SITemplateModel) => ({
                          ...item,
                          reportType: item.reportType,
                          //   templateName: {item.templateName},

                          action: <ActionButtons2 id={item.id ?? ''} editAction={() => onUpdate(item)} onDelete={(id) => onDelete(id)} />,
                      }))
                    : [];
                setDatas(tableData ?? []);
            })
            .catch(() => {});
    };

    const onAdd = async () => {
        var rs = await openModal({
            title: t(Translations.equipment.templateCategories),
            body: <SIAddOrUpdateComponent onClose={onClose} onCompleted={onCompleted} />,
            modalSize: 'xl',
        });
        if (rs) handleFetchData(defaultPaginationRequest);
    };

    const onDelete = async (id: string) => {
        siTemplateApi
            .deleteTemplate(id)
            .then(() => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch(() => {});
    };

    const onUpdate = async (template: SITemplateModel) => {
        var rs = await openModal({
            title: t(Translations.equipment.templateCategories),
            body: <SIAddOrUpdateComponent onClose={onClose} templateId={template.id} onCompleted={onCompleted} />,
            modalSize: 'xl',
        });
        if (rs) handleFetchData(defaultPaginationRequest);
    };

    const cols = useMemo(
        () => [
            {
                field: 'templateName',
                title: t(Translations.common.name),
            },
            {
                field: 'reportType',
                title: t(Translations.common.reportType),
            },
            {
                field: 'createdUserName',
                title: t(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="row w-100">
                        <CompanySelect name="companyId" control={control} onCompanyChange={setCompanyId} md={4} />
                        <SelectField
                            label={t(Translations.common.reportType)}
                            control={control}
                            name="reportType"
                            options={reportTypes}
                            isMutiple={false}
                            unSelectValue={''}
                            defaultValue={SIReportType.Daily}
                            md={4}
                            onValueChange={(value) => {
                                setReportType(value);
                            }}
                        />
                        <div className="col-md-4 d-flex justify-content-end">
                            <div>
                                <AddButton isRender={true} onClick={onAdd} />
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <ReposiveTable datas={datas} emptyLayout={<Empty />} columns={cols} />
            {Prompt}
        </div>
    );
});

export default SITemplateListPage;
