import clientRequest from './client';
import { ContractorCreateRequest, ContractorResponse, ContractorUpdateRequest, GoldenRuleResponse, GoldenRuleUpdateRequest } from '../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../models/common';

const baseUrl = '/contractor';
const configContractorApi = {
    getByCurrentCustomer: (paginationRequest: PaginationBaseRequest, isLoading: boolean = true) => {
        const url = `${baseUrl}/getbyindex`;
        var rs = clientRequest.getNonWrap<PaginationBase<ContractorResponse>>({
            data: paginationRequest,
            endpoint: url,
            isLoading: isLoading,
        });
        return rs;
    },
    async create(data: ContractorCreateRequest) {
        const url = `${baseUrl}/create`;
        return await clientRequest.post<string>(url, data, true, true);
    },
    async update(data: ContractorUpdateRequest) {
        const url = `${baseUrl}/update`;
        return await clientRequest.postNonWrap({
            endpoint: url,
            data: data,
        });
    },
    async getById(id: string) {
        const url = `${baseUrl}/getbyid/${id}`;
        return await clientRequest.get<GoldenRuleResponse>(url);
    },
    async delete(id: string) {
        const url = `${baseUrl}/delete/${id}`;
        return await clientRequest.postNonWrap({
            endpoint: url,
        });
    },
};

export default configContractorApi;
