import { RiskLevelCreateRequest } from 'api/models/organization';
//react-bootstrap
import { Button, Col, Row, Spinner } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { InputField, SelectField, TextAreaField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { SaveButton } from 'components/Button';

const ConfigRiskLevelCreatePage = memo(() => {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        fieldNameVn: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        note: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        reset,
    } = useForm<{ fieldNameVn: string; note?: string }>({
        defaultValues: {
            fieldNameVn: '',
            note: '',
        },
        resolver: yupResolver(schema),
    });

    const onAdd = () => {
        const values = getValues();

        const _addData: RiskLevelCreateRequest = {
            fieldNameVn: values.fieldNameVn,
            note: values.note,
        };

        riskLevelApi
            .create(_addData)
            .then(() => {
                reset((values) => ({ ...values, fieldNameVn: '', note: '' }));
            })
            .catch((n) => {});
    };

    return (
        <div>
            <Card>
                <Card.Header></Card.Header>
                <Card.Body>
                    <form className="row justify-content-center" onSubmit={handleSubmit(onAdd)}>
                        <Row>
                            <InputField md={12} xs={12} name="fieldNameVn" control={control} label={t(Translations.form.name)} placeholder="" />
                            <TextAreaField md={12} xs={12} name="note" control={control} label={t(Translations.form.content)} placeholder="" />
                            <Col md={12} xs={12}>
                                <div className="d-flex justify-content-end">
                                    <SaveButton type="submit" />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigRiskLevelCreatePage;
