import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { PaginationTable } from 'components/shared/PaginationTable';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { TNCourseFilterRequest, TNMyCourseResponse } from 'api/v2/training/models/tnCourse.model';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { lang } from 'i18n/lang';
import tnMyCourseAPI from 'api/v2/training/tnMyCourseApi';
import { formatDate, formatDateTime } from 'helper';
import { Badge } from 'react-bootstrap';
import { TNSubmissionStatus } from 'api/v2/training/models/tnCourseSubmission.model';

const defaultFromDate = new Date();

defaultFromDate.setDate(defaultFromDate.getDate() - 7);
export const tnRenderBagdeStatus = (status: TNSubmissionStatus) => {
    switch (status) {
        case TNSubmissionStatus.Active:
            return <Badge bg="warning">Chưa làm</Badge>;
        case TNSubmissionStatus.Remake:
            return <Badge bg="danger">Yêu cầu làm lại</Badge>;
        case TNSubmissionStatus.UserCompleted:
            return <Badge bg="info">Chờ đánh giá</Badge>;
        default:
            return <Badge bg="success">Hoàn thành</Badge>;
    }
};
const TNMyCoursesPage = () => {
    const [paginationData, setPaginationData] = useState<PaginationBase<any>>();
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const cols = useMemo(
        () => [
            {
                field: 'title',
                title: 'Tên khóa học',
            },
            {
                field: 'topicName',
                title: 'Chủ đề',
            },

            {
                field: 'status',
                title: 'Trạng thái',
            },
            {
                field: 'point',
                title: 'Điểm',
            },
            {
                field: 'quizStartDate',
                title: 'Bắt đầu làm bài',
            },

            {
                field: 'quizEndDate',
                title: 'Hết hạn',
            },

            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    const handleFetchData = async (filter: PaginationBaseRequest) => {
        tnMyCourseAPI
            .getPagination(filter as unknown as TNCourseFilterRequest)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n) => {
                            return {
                                ...n,
                                status: tnRenderBagdeStatus(n.status),
                                quizEndDate: n.quizEndDate && formatDate(n.quizEndDate),
                                quizStartDate: n.quizStartDate && formatDateTime(n.quizStartDate),
                                action: <ActionButtons2 id={n.id} onViewDetail={() => onViewCourse(n)} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    const onViewCourse = (item: TNMyCourseResponse) => {
        navigate(`${Routers.trainingOnline.myCourseTakeQuiz}`.replace(':id', item.id));
    };

    return <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={paginationData} isStriped={true} disableSearch={true} wrapperClass="mt-5" />;
};

export default TNMyCoursesPage;
