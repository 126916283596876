import { PromptModal } from 'components/Modal/PromptModal/index';
//components
import { yupResolver } from '@hookform/resolvers/yup';
import contractorApi from 'api/v2/configContractorApi';
import { ContractorCreateRequest, ContractorResponse, ContractorUpdateRequest } from 'api/models/organization';

import { CustomToggle, FancyTable } from 'components/shared';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { defaultPaginationRequest, PaginationBase, PaginationBaseRequest } from 'api/models/common';
import { AddButton } from 'components/Button';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { PaginationTable } from 'components/shared/PaginationTable';
import usePromptInputText from 'components/Modal/PromptInputText';
import { useAuth } from 'providers/contexts';
const ContractorPage = memo(() => {
    const { t } = useTranslation();

    const [data, setData] = useState<PaginationBase<ContractorResponse>>();
    const { openPromptInputText, PromptInputText } = usePromptInputText();

    const { userPms } = useAuth();
    useEffect(() => {
        handleFetchData(defaultPaginationRequest);
    }, []);

    const handleFetchData = (paginate?: PaginationBaseRequest) => {
        if (!paginate) paginate = defaultPaginationRequest;
        contractorApi
            .getByCurrentCustomer(paginate)
            .then((result: PaginationBase<ContractorResponse>) => {
                var dt: PaginationBase<ContractorResponse> = {
                    ...result,
                    datas: (result?.datas ?? []).map((item) => ({
                        ...item,

                        action: (
                            <ActionButtons2
                                id={item.id}
                                deletePms={userPms.orgAdmin}
                                editPms={userPms.orgAdmin}
                                editAction={() => onUpdate(item.contractorName, item.id)}
                                onDelete={(id) => onDelete(item.id)}
                            />
                        ),
                    })),
                };
                setData(dt);
            })
            .catch((err) => {});
    };

    const onDelete = async (id: string) => {
        await contractorApi
            .delete(id ?? '')
            .then(() => {
                handleFetchData();
            })
            .catch((err) => {});
    };

    const onUpdate = async (oldName: string, id: string) => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: oldName,
        });
        text &&
            contractorApi
                .update({ contractorName: text, id: id })
                .then(() => {
                    handleFetchData();
                })
                .catch((err) => {});
    };
    const onAdd = async () => {
        const text: string = await openPromptInputText({
            title: t(Translations.common.name),
            defaultValue: '',
        });
        text &&
            contractorApi
                .create({ contractorName: text })
                .then(() => {
                    handleFetchData();
                })
                .catch((err) => {});
    };

    const schema = yup.object().shape({
        parentId: yup.string(),
    });

    const cols = useMemo(
        () => [
            {
                field: 'logo',
                title: '',
            },
            {
                field: 'contractorName',
                title: t(Translations.form.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="text-end w-100 d-flex justify-content-end align-items-center">
                        <AddButton isRender={true} onClick={onAdd} />
                    </div>
                </Card.Body>
            </Card>
            {PromptInputText}
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={data} isStriped={true} wrapperClass="mt-5" />
        </div>
    );
});

export default ContractorPage;
