//react-bootstrap
import { Accordion, Button, Form } from 'react-bootstrap';

//components
import riskLevelApi from 'api/v2/common/riskLevel/riskLevelApi';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Plus } from 'assets/icons/outlined';
import { Empty } from 'components/shared';
import Card from 'components/shared/Card';
import Routers from 'router/const/routers';
import Translations from 'i18n/translation';
import { useAuth, useCustomer } from 'providers/contexts';
import { ResponseApi } from 'api/models/common/apiResponse';
import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { RiskLevelResponse } from 'api/models/organization';
import { AddButtonNavigate, DeleteButton, UpdateButtonNavigate } from 'components/Button';
import { PaginationBase } from 'api/models/common';
import ToastConfirm from 'components/Modal/ToastConfirm';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { use } from 'i18next';

const ConfirmModal = lazy(() => import('components/shared/ConfirmModal'));

const ConfigRiskLevelPage = memo(() => {
    const { userPms } = useAuth();
    const [data, setData] = useState<PaginationBase<RiskLevelResponse>>();
    const navigate = useNavigate();

    const cols = useMemo(
        () => [
            {
                field: 'fieldNameVn',
                title: lang.text(Translations.form.name),
            },
            {
                field: 'fieldNameEs',
                title: lang.text(Translations.form.name),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        riskLevelApi
            .getByCurrentCustomer()
            .then((result: PaginationBase<RiskLevelResponse>) => {
                var dt: PaginationBase<RiskLevelResponse> = {
                    ...result,
                    datas: (result?.datas ?? []).map((item) => ({
                        ...item,

                        action: (
                            <ActionButtons2
                                id={item.id}
                                deletePms={userPms.orgAdmin}
                                editPms={userPms.orgAdmin}
                                editAction={() => navigate(Routers.config.riskLevelUpdate.replace(':id', item.id))}
                                onDelete={(id) => onDelete(item.id)}
                            />
                        ),
                    })),
                };
                setData(dt);
            })
            .catch((n) => {});
    };

    const onDelete = (id: string) => {
        riskLevelApi
            .delete(id)
            .then(() => {
                handleFetchData();
            })
            .catch((n) => {});
        return Promise.resolve();
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <div className="text-end w-100">
                        <AddButtonNavigate url={Routers.config.riskLevelCreate} isRender={true} />
                    </div>
                </Card.Body>
            </Card>
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={data} isStriped={true} wrapperClass="mt-5" />
        </div>
    );
});

export default ConfigRiskLevelPage;
{
    /* <ToastConfirm onConfirm={() => handleDeleteData(item.id)}>
<DeleteButton />
</ToastConfirm>
<UpdateButtonNavigate url={} /> */
}
