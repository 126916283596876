import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

interface Field {
    propertyName: string; // The key in the data object
    displayName: any; // The label to show for this field
}

interface BoxCardItemProps {
    fields: Field[]; // Array of fields to display
    data: object; // The data object containing the field values
}

const BoxCardItem: React.FC<BoxCardItemProps> = ({ fields, data }) => {
    return (
        <Card className="mb-3 shadow-sm border-0" style={{ borderRadius: '10px' }}>
            <Card.Body>
                {fields.map((field, index) => {
                    const value = (data as any)[field.propertyName]; // Access properties dynamically

                    return (
                        <Row
                            key={index}
                            className={`py-2 ${index < fields.length - 1 ? 'border-bottom' : ''}`}
                            style={{ borderColor: '#e9ecef' }} // Light gray border color
                        >
                            <Col xs={5} className="text-muted">
                                {field.displayName}
                            </Col>
                            <Col xs={7} className="text-end text-ellipsis ">
                                <span
                                    className={
                                        typeof value === 'string' && value.startsWith('-') ? 'text-danger fw-bold' : typeof value === 'string' && value.startsWith('+') ? 'text-success fw-bold' : ''
                                    }
                                >
                                    {value ?? ''}
                                </span>
                            </Col>
                        </Row>
                    );
                })}
            </Card.Body>
        </Card>
    );
};

export default BoxCardItem;
