import Card from 'components/shared/Card';
import { CustomToggle, SelectLanguage } from 'components/shared';
import React, { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { lang } from 'i18n/lang';
import Routers from 'router/const/routers';
import { useAuth } from 'providers/contexts';
import { useDispatch, useSelector } from 'react-redux';
import * as SettingSelector from 'app/setting/selectors';
import Translations from 'i18n/translation';
import { setShowCustomerModal } from 'app/appSlice';
import useLayout, { LAYOUT_TYPE } from 'hooks/useLayout';
import { UserCircle } from 'heroicons-react';

export default function UserProfileActions() {
    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const themeFontSize = useSelector(SettingSelector.theme_font_size);
    const headerNavbar = useSelector(SettingSelector.header_navbar);
    const { tokenProfile, logOut } = useAuth();
    const [show1, setShow1] = useState(false);
    const [currentLayout, setCurrentLayout] = useLayout();

    const dispatch = useDispatch();
    // show modal choose customer
    const showCustomerModal = () => {
        dispatch(setShowCustomerModal(true));
    };

    const switchLayout = () => {
        setCurrentLayout(currentLayout === LAYOUT_TYPE.BOX ? LAYOUT_TYPE.DEFAULT : LAYOUT_TYPE.BOX);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    useEffect(() => {
        if (headerNavbar === 'navs-sticky' || headerNavbar === 'nav-glass') {
            window.onscroll = () => {
                if (document.documentElement.scrollTop > 50) {
                    document.getElementsByTagName('nav')[0].classList.add('menu-sticky');
                } else {
                    document.getElementsByTagName('nav')[0].classList.remove('menu-sticky');
                }
            };
        }

        document.getElementsByTagName('html')[0].classList.add(themeFontSize);

        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });

    return (
        <div>
            <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
                <Dropdown as="li" className="nav-item iq-tour ps-3 ps-lg-0">
                    {/* icon user */}
                    <Dropdown.Toggle as={CustomToggle} variant="py-0  d-flex align-items-center nav-link">
                        <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                            <UserCircle className="icon-30" />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="end">
                        <Dropdown.Item>
                            <Link to={Routers.user.profile}>{lang.text(Translations.common.profile)}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link to={Routers.auth.changePassword}>{lang.text(Translations.common.changePassword)}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item className={`${tokenProfile?.isSuperAdmin ? 'd-block' : 'd-none'}`} onClick={showCustomerModal}>
                            <span className="dropdown-text">{lang.text(Translations.form.customer.name)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item style={{ height: 30 }}>
                            <div className="d-flex gap-3 " onClick={() => switchLayout()}>
                                <Form.Label htmlFor="flexSwitchCheckDefault" className="dropdown-text">
                                    {lang.text(Translations.common.oldLayout)}
                                </Form.Label>
                                <Form.Check className="form-switch d-flex">
                                    <Form.Check.Input
                                        defaultChecked={currentLayout === LAYOUT_TYPE.BOX}
                                        type="checkbox"
                                        className="inline-block"
                                        id="flexSwitchCheckDefault"
                                        checked={currentLayout === LAYOUT_TYPE.BOX}
                                    />
                                </Form.Check>
                            </div>
                        </Dropdown.Item>
                        <hr className="dropdown-divider" />
                        <Dropdown.Item onClick={() => logOut()}>{lang.text(Translations.common.logout)}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {/* Notification */}
                <Dropdown as="li" className="nav-item d-none">
                    <Dropdown.Toggle as={CustomToggle} variant="nav-link ">
                        <div className="btn btn-primary btn-icon btn-sm rounded-pill btn-action">
                            <span className="btn-inner">
                                <i className="icon">
                                    <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            opacity="0.4"
                                            d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04991C4.26991 21.0002 1.99991 18.7502 1.99991 15.9602V15.9502C1.99991 15.9502 2.00591 11.5242 2.01391 9.29821C2.01491 8.88021 2.49491 8.64621 2.82191 8.90621C5.19791 10.7912 9.44691 14.2282 9.49991 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.97721C21.5069 8.71621 21.9889 8.95021 21.9899 9.36721C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M21.476 5.6736C20.61 4.0416 18.906 2.9996 17.03 2.9996H7.05001C5.17401 2.9996 3.47001 4.0416 2.60401 5.6736C2.41001 6.0386 2.50201 6.4936 2.82501 6.7516L10.25 12.6906C10.77 13.1106 11.4 13.3196 12.03 13.3196C12.034 13.3196 12.037 13.3196 12.04 13.3196C12.043 13.3196 12.047 13.3196 12.05 13.3196C12.68 13.3196 13.31 13.1106 13.83 12.6906L21.255 6.7516C21.578 6.4936 21.67 6.0386 21.476 5.6736Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </i>
                            </span>
                            <span className="notification-alert"></span>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="end p-0 sub-drop">
                        <Card className="m-0 shadow-none ">
                            <Card.Header className="py-3 d-flex justify-content-between bg-primary">
                                <div className="header-title">
                                    <h5 className="mb-0 text-white">All Carts</h5>
                                </div>
                            </Card.Header>
                            {/* <Card.Body className="p-0 max-17 scroll-thin">
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img1}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Biker’s Jacket</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger " size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img2}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Casual Shoes</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger " size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img3}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Knitted Shrug</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button size="sm" variant="icon text-danger">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img4}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Blue Handbag</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger" size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img1}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Biker’s Jacket</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger" size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img2}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Casual Shoes</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger " size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img3}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Knitted Shrug</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger " size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
    <div className="iq-sub-card">
      <div className="d-flex align-items-center">
        <Image
          className="p-1 avatar-40 rounded-pill bg-soft-primary"
          src={img4}
          alt=""
          loading="lazy"
        />
        <div className="ms-3 flex-grow-1">
          <h6 className="mb-0 ">Blue Handbag</h6>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0">$56.00</p>
          </div>
        </div>
        <Button variant="icon text-danger " size="sm">
          <div className="btn-inner">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.4"
                d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                fill="currentColor"
              ></path>
              <path
                d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Button>
      </div>
    </div>
  </Card.Body> */}
                            <Card.Footer className="p-0">
                                <div className="d-grid">
                                    <Link to="/e-commerce/order-process" className="btn btn-primary">
                                        View All
                                    </Link>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Dropdown.Menu>
                </Dropdown>
            </ul>
        </div>
    );
}
