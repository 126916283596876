import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import Card from 'components/shared/Card';
import { useNavigate } from 'react-router-dom';

import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { CompanySelect } from 'components/Common';
import Translations from 'i18n/translation';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FilterButton } from 'components/Button/FilterButton';
import useQueryParams from 'hooks/useQueryParams';
import { formatDateTime } from 'helper';
import { PaginationTable } from 'components/shared/PaginationTable';
import { lang } from 'i18n/lang';
import DocGroupSelect from './docGroupSelect';
import { DocumentFilterRequest, DocumentResponse } from 'api/v2/document/model/document.model';
import documentApi from 'api/v2/document/documentApi';
import { AddButton } from 'components/Button';
import useModalHook from 'components/ModalHook';
import DocumentUpload from './upload.component';
import LinkFileDownload from 'components/Common/LinkFile';

// eslint-disable-next-line react-hooks/exhaustive-deps
const DocumentListPage = () => {
    const cols = useMemo(
        () => [
            {
                field: 'name',
                title: lang.text(Translations.document.name),
            },
            {
                field: 'groupName',
                title: lang.text(Translations.document.group),
            },
            {
                field: 'tag',
                title: lang.text(Translations.document.tag),
            },
            {
                field: 'version',
                title: lang.text(Translations.document.version),
            },
            {
                field: 'approvedDate',
                title: lang.text(Translations.document.approvedDate),
            },
            {
                field: 'link',
                title: lang.text(Translations.document.link),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    // const reportStatus = useMemo(() => SORReportHelper.getReportStatusOptions(), []);
    const [currentDetailId, setCurrentDetailId] = useState<string | undefined | null>(undefined);

    const { openModal, onClose, onCompleted, Prompt } = useModalHook();
    var navigate = useNavigate();
    const { t } = useTranslation();
    const [paginationData, setPaginationData] = useState<PaginationBase<DocumentResponse>>();
    const [companyId, setCompanyId] = useState<string | undefined>(undefined);
    const { control, handleSubmit, getValues, reset, formState } = useForm();

    const { getQueryParam } = useQueryParams();
    useEffect(() => {
        let id = getQueryParam('id');
        setCurrentDetailId(id);
    }, []);

    const onDelete = (id: string) => {
        return documentApi
            .delete(id)
            .then((n) => {
                handleFetchData(defaultPaginationRequest);
            })
            .catch((e) => {});
    };
    const handleFetchData = async (pagination: PaginationBaseRequest) => {
        var form = getValues();
        // return;
        let filter = {
            ...pagination,
            ...form,
        } as DocumentFilterRequest;
        documentApi
            .getPagination(filter)
            .then((n) => {
                let pagi: PaginationBase = {
                    ...n,
                    datas:
                        n.datas?.map((n: DocumentResponse) => {
                            return {
                                ...n,
                                approvedDate: n.approvedDate && formatDateTime(n.approvedDate),
                                link: (
                                    <ul>
                                        {n.link && (
                                            <li>
                                                <a href={n.link} target="_blank">
                                                    {n.link}
                                                </a>
                                            </li>
                                        )}
                                        {n.file?.filePath && (
                                            <li>
                                                <LinkFileDownload path={n.file.filePath} fileName={n.file.fileName} />
                                            </li>
                                        )}
                                    </ul>
                                ),

                                action: <ActionButtons2 id={n.id ?? ''} onDelete={onDelete} />,
                            };
                        }) ?? [],
                };
                setPaginationData(pagi);
            })
            .catch((e) => {});
    };

    const onUpload = async () => {
        var rs = await openModal({
            title: t(Translations.equipment.templateCategories),
            body: <DocumentUpload onClose={onClose} onCompleted={onCompleted} />,
        });
    };

    return (
        <Fragment>
            <div>
                <Card>
                    <Card.Body>
                        <form className="w-100" onSubmit={handleSubmit(() => handleFetchData(defaultPaginationRequest))}>
                            <div className="row">
                                <CompanySelect md={4} name="companyId" control={control} required={false} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                                <DocGroupSelect companyId={companyId} md={4} isMulti={true} control={control} name="groupIds" />
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-end">
                                    <AddButton isRender={true} onClick={onUpload} text={lang.text(Translations.common.upload)} />
                                    <FilterButton type="submit" />
                                </div>
                            </div>
                        </form>
                    </Card.Body>
                </Card>
            </div>
            <PaginationTable
                onSearch={(pagi) => {
                    defaultPaginationRequest.keySearch = pagi.keySearch;
                }}
                onPaginate={handleFetchData}
                // onRowClick={(item) => {
                //     setCurrentDetailId(item.id);
                // }}
                columns={cols}
                pagination={paginationData}
                isStriped={true}
                disableSearch={false}
                wrapperClass="mt-5"
            />
            {Prompt}
        </Fragment>
    );
};

export default DocumentListPage;
