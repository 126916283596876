import { Fragment, useEffect, useState } from 'react';

import { InputField, RadioField, TextAreaField, UploadImageField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { lang } from 'i18n/lang';
import { AreaSelect, CompanySelect, ContractorSelect } from 'components/Common';
import RiskLevelSelect from 'components/RiskLevelSelect';
import { SaveButton } from 'components/Button';
import UserSelectField from 'components/Common/UserSelect';
import DateTimeField from 'components/FormControl/DateTimeField';
import { KPIReportCreateRequest, KPIReportItemCreateRequest } from 'api/v2/kpi/model/kpiReport.model';
import kpiReportApi from 'api/v2/kpi/kpiReportApi';
import KPITypeSelect from '../kpiType/kpiTypeSelect.component';
import { toast } from 'react-toastify';

const defaultDate = new Date();
const KPIReportCreatePage = () => {
    const { t } = useTranslation();

    const [companyId, setCompanyId] = useState<string>('');
    const [typeId, setTypeId] = useState<string>('');
    const [templates, setTemplates] = useState<KPIReportItemCreateRequest[]>([]);
    const schema = yup.object().shape({
        date: yup.date().required(),
        companyId: yup.string().required(),
        typeId: yup.string().required(),
    });

    /*get template for create when type change */
    useEffect(() => {
        if (typeId?.hasValue()) {
            kpiReportApi
                .getTemplateForCreate(companyId, typeId)
                .then((res) => {
                    setTemplates(res);
                })
                .catch(() => {});
        } else {
            setTemplates([]);
        }
    }, [typeId]);
    const {
        control,
        watch,
        handleSubmit,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const SaveChange = () => {
        var form = getValues() as any;
        var payload: KPIReportCreateRequest = {
            companyId: companyId,
            typeId: typeId,
            date: new Date(form.date).toDateOnly(),
            details: templates,
        };
        templates.forEach((item) => {
            var value = form[item.templateId + 'value'];
            if (!value?.hasValue()) {
                toast.error(lang.text(Translations.error.requiredAllFields));
                return;
            }
            item.value = parseInt(form[item.templateId + 'value']);
            item.description = form[item.templateId + 'description'];
        });
        kpiReportApi
            .create(payload)
            .then((res) => {})
            .catch(() => {});
    };

    return (
        <form onSubmit={handleSubmit(SaveChange)}>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <h4 className="card-title  ">
                        <span>Tạo báo cáo</span>{' '}
                    </h4>
                    <SaveButton type="submit" />
                </Card.Header>
                <Card.Body>
                    <div className="row">
                        <DateTimeField
                            label={lang.text(Translations.kpis.reportDate)}
                            md={4}
                            isShowTimePicker={false}
                            control={control}
                            readOnly={false}
                            name={'date'}
                            defaultDate={defaultDate}
                            onValueChange={(date) => {}}
                        />
                        <CompanySelect md={4} name="companyId" control={control} required={true} disable={false} readOnly={false} onCompanyChange={setCompanyId} />
                        <KPITypeSelect isRequiredCompany={true} control={control} companyId={companyId} name="typeId" md={4} required={true} onValueChange={(id) => setTypeId(id)} />
                    </div>
                </Card.Body>
            </Card>
            {templates.hasValue() && (
                <Card>
                    <Card.Body>
                        <div className="row">
                            {templates.map((item, index) => (
                                <Fragment key={index}>
                                    <div className="row" key={index}>
                                        <div className="col-md-5">
                                            <span>{item.name}</span>
                                        </div>
                                        <InputField name={item.templateId + 'value'} placeholder={item.unit} control={control} required={true} md={2} type="number" />
                                        <InputField name={item.templateId + 'description'} placeholder={lang.text(Translations.common.description)} control={control} required={true} md={5} />
                                    </div>
                                    <hr />
                                </Fragment>
                            ))}
                        </div>
                    </Card.Body>
                </Card>
            )}
        </form>
    );
};

export default KPIReportCreatePage;
