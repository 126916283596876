import { Fragment, memo, useEffect, useState } from 'react';

//react-bootstrap
import { Button, Container, Dropdown, Form, Nav, Navbar } from 'react-bootstrap';

//router
import { Link, Router, useLocation, useNavigate } from 'react-router-dom';

//component
import Card from 'components/shared/Card';
import { CustomToggle, SelectLanguage } from 'components/shared';

//img

// logo
import { Logo } from 'components/shared';

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from 'app/setting/selectors';

import { setShowCustomerModal } from 'app/appSlice';
import { ZoomIn, ZoomOut } from 'assets/icons';

import { fullscreen, minisidebar, hidenMiniSideBar } from 'components/builder/actions';
import { isMobileDevide } from 'helper';
import { findRouteByCurrentPath } from 'router/const/pageInfos';
import { lang } from 'i18n/lang';
import MobileSidebar from './mobileSideMenu';
import UserProfileActions from 'layouts/component/userProfileActions';
import { useQuery } from 'hooks/useQuery';
import useMenu from 'hooks/useMenu';
import { ArrowLeft } from 'heroicons-react';
import { BaseButton } from 'components/Button/BaseButton';
import { use } from 'i18next';
import { menusBoxGroup } from 'router/menuRouters';

const BoxLayoutHeader = memo(() => {
    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const themeFontSize = useSelector(SettingSelector.theme_font_size);
    const headerNavbar = useSelector(SettingSelector.header_navbar);
    const { userMenus } = useMenu();
    const location = useLocation();
    const navigate = useNavigate();
    const [titlePage, setTitlePage] = useState('');

    // set hide menu vertical sizebar if is mobile
    useEffect(() => {
        let groupMenu = menusBoxGroup.find((menu) => menu.path === location.pathname);
        if (groupMenu) {
            setTitlePage(groupMenu.name);
        } else {
            setTitlePage(findRouteByCurrentPath()?.title ?? '');
        }
    }, [location.pathname]);

    // set current page name
    useEffect(() => {
        if (headerNavbar === 'navs-sticky' || headerNavbar === 'nav-glass') {
            window.onscroll = () => {
                if (document.documentElement.scrollTop > 50) {
                    document.getElementsByTagName('nav')[0].classList.add('menu-sticky');
                } else {
                    document.getElementsByTagName('nav')[0].classList.remove('menu-sticky');
                }
            };
        }

        document.getElementsByTagName('html')[0].classList.add(themeFontSize);

        //offcanvase code
        const result = window.matchMedia('(max-width: 1200px)');
        window.addEventListener('resize', () => {
            if (result.matches === true) {
                if (show1 === true) {
                    document.documentElement.style.setProperty('overflow', 'hidden');
                } else {
                    document.documentElement.style.removeProperty('overflow');
                }
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        });
        if (window.innerWidth <= 1200) {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            } else {
                document.documentElement.style.removeProperty('overflow');
            }
        } else {
            document.documentElement.style.removeProperty('overflow');
        }
    });

    const [show1, setShow1] = useState(false);
    return (
        <Navbar expand="xl" className={`nav iq-navbar header-hover-menu py-0 my-0 left-border rounded-bottom ${headerNavbar} ${navbarHide.join(' ')}`}>
            <Container className="navbar-inner px-2 py-2">
                {location.pathname !== '/' ? (
                    <div className="d-flex gap-2 align-items-center">
                        <BaseButton isRender text=" " className="" icon={<ArrowLeft />} onClick={() => navigate(-1)} />
                    </div>
                ) : (
                    <MobileSidebar />
                )}

                <div className="d-flex align-items-center text-white font-bold">{lang.text(titlePage)}</div>
                <UserProfileActions />
                {/* <div className="d-flex gap-2 align-items-center">
                        <BaseButton isRender text="Quay lại" icon={<ArrowLeft />} onClick={() => navigate(Routers.root)} />
                        <h5 className="menu-group-name">{lang.text(userMenus?.find((menu) => menu.key === pathname)?.groupName || 'No title')}</h5>
                    </div> */}
            </Container>
        </Navbar>
    );
});

BoxLayoutHeader.displayName = 'Header';
export default BoxLayoutHeader;
