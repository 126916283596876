import clientRequest from '../../client';
import { RiskLevelCreateRequest, RiskLevelResponse, RiskLevelUpdateRequest } from '../../../models/organization';
import { PaginationBase, PaginationBaseRequest } from '../../../models/common';

const baseUrl = '/risklevel';
const riskLevelApi = {
    getByCurrentCustomer: async () => {
        var paginationRequest: PaginationBaseRequest = {
            rowsPerPage: 100,
            currentPage: 1,
        };
        const url = `${baseUrl}/getbyindex`;
        var rs = await clientRequest.getNonWrap<PaginationBase<RiskLevelResponse>>({
            endpoint: url,
            data: paginationRequest,
        });
        return rs;
    },
    create(data: RiskLevelCreateRequest) {
        const url = `${baseUrl}/create`;
        return clientRequest.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    update(data: RiskLevelUpdateRequest) {
        const url = `${baseUrl}/update`;
        return clientRequest.postNonWrap<string>({
            endpoint: url,
            data: data,
        });
    },
    getById(id: string) {
        const url = `${baseUrl}/getbyid/${id}`;
        return clientRequest.getNonWrap<RiskLevelResponse>({
            endpoint: url,
        });
    },
    delete(id: string) {
        const url = `${baseUrl}/delete/${id}`;
        return clientRequest.postNonWrap<string>({
            endpoint: url,
        });
    },
};

export default riskLevelApi;
