//react-bootstrap
import { Accordion, Button, Form } from 'react-bootstrap';

//components
import configGoldenRuleApi from 'api/v2/configGoldenRuleApi';
import { GoldenRuleResponse } from 'api/models/organization';
import { Empty } from 'components/shared';
import Card from 'components/shared/Card';
import Routers from 'router/const/routers';
import { Suspense, lazy, memo, useEffect, useState } from 'react';
import { PaginationBase } from 'api/models/common';
import { AddButtonNavigate, DeleteButton, UpdateButtonNavigate } from 'components/Button';
import ToastConfirm from 'components/Modal/ToastConfirm';

const ConfigGoldenRulesPage = memo(() => {
    const [data, setData] = useState<GoldenRuleResponse[]>([]);

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleFetchData = () => {
        configGoldenRuleApi
            .getByCurrentCustomer()
            .then((res: PaginationBase<GoldenRuleResponse>) => {
                setData(res?.datas ?? []);
            })
            .catch((n) => {});
    };

    const handleDeleteData = async (id: string) => {
        await configGoldenRuleApi.delete(id);
        handleFetchData();
    };

    return (
        <div>
            <Card>
                <Card.Header>
                    <div className="text-end w-100">
                        <AddButtonNavigate url={Routers.config.goldenRulesCreate} isRender={true} />
                    </div>
                </Card.Header>
                <Card.Body>
                    {/* Table */}
                    {data.length ? (
                        <Accordion>
                            {data.map((item, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header>{item.name}</Accordion.Header>
                                    <Accordion.Body>
                                        <Card.Body className="p-0">
                                            <div>
                                                {item.content && <span className="d-block">{item.content}</span>}

                                                <div className="d-lg-flex justify-content-end gap-2">
                                                    <DeleteButton showConfirm={true} onConfirmed={() => handleDeleteData(item.id)} />
                                                    <UpdateButtonNavigate url={Routers.config.goldenRulesUpdate.replace(':id', item.id)} />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    ) : (
                        <Empty />
                    )}
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigGoldenRulesPage;
