//react-bootstrap
import { Button, Row, Spinner } from 'react-bootstrap';

//components
import { yupResolver } from '@hookform/resolvers/yup';
import configGoldenRuleApi from 'api/v2/configGoldenRuleApi';
import { GoldenRuleResponse, GoldenRuleUpdateRequest } from 'api/models/organization';
import { selectLanguage } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import { Save } from 'assets/icons/outlined';
import { InputField, TextAreaField } from 'components/FormControl';
import Card from 'components/shared/Card';
import Translations from 'i18n/translation';
import { Suspense, lazy, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ConfirmModal } from 'components/shared';
import { SaveButton } from 'components/Button';

const ConfigGoldrenRulesEditPage = memo(() => {
    const { t } = useTranslation();
    const params = useParams();

    const schema = yup.object().shape({
        name: yup.string().required(t(Translations.form.validate.nameCannotEmpty)),
        content: yup.string(),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue,
    } = useForm<{ name: string; content?: string }>({
        defaultValues: {
            name: '',
            content: '',
        },
        resolver: yupResolver(schema),
    });

    // State

    const [openConfirm, setOpenConfirm] = useState(false);
    const [data, setData] = useState<GoldenRuleUpdateRequest>({
        name: '',
        content: '',
        id: '',
    });

    useEffect(() => {
        params.id &&
            configGoldenRuleApi
                .getById(params.id)
                .then((result: GoldenRuleResponse) => {
                    setData(result);
                    setValue('name', result.name);
                    setValue('content', result.content ?? '');
                })
                .catch((n) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle methods
    const handleConfirmSubmit = () => {
        setOpenConfirm(true);
    };

    const handleUpdate = async () => {
        const values = getValues();

        const _addData: GoldenRuleUpdateRequest = {
            id: data.id,
            name: values.name,
            content: values.content,
        };

        await configGoldenRuleApi.update(_addData);
        setOpenConfirm(false);
    };

    return (
        <div>
            {/* Confirm add category */}
            <Suspense fallback={<div>Modal is Loading...</div>}>
                <ConfirmModal
                    show={openConfirm}
                    content={t(Translations.modal.confirm.confirmUpdate)}
                    handleCancel={() => {
                        setOpenConfirm(false);
                    }}
                    handleAccept={handleUpdate}
                />
            </Suspense>

            <Card>
                <Card.Body>
                    <form className="row justify-content-center" onSubmit={handleSubmit(handleConfirmSubmit)}>
                        <Row>
                            <InputField md={12} name="name" control={control} label={t(Translations.form.name)} placeholder="" />
                            <TextAreaField md={12} name="content" control={control} label={t(Translations.form.content)} placeholder="" />
                        
                            <div className="d-flex justify-content-end">
                                <SaveButton type="submit" />
                            </div>
                        </Row>
                    </form>
                    
                </Card.Body>
            </Card>
        </div>
    );
});

export default ConfigGoldrenRulesEditPage;
