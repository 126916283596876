import { useState, useEffect } from 'react';
const key = 'USER_LAYOUT';
const useLayout = () => {
    const [currentLayout, setLayout] = useState(() => {
        const storedLayout = getCurrentLayout();
        return storedLayout ;
    });

    useEffect(() => {
        localStorage.setItem(key, currentLayout);
    }, [key, currentLayout]);

    return [currentLayout, setLayout] as const;
};
export const getCurrentLayout = () => localStorage.getItem(key) ?? LAYOUT_TYPE.DEFAULT
export const LAYOUT_TYPE = {
    BOX: 'BOX',
    DEFAULT: 'DEFAULT',
};

export default useLayout;
