import { yupResolver } from '@hookform/resolvers/yup';
import { LoginRequest } from 'api/v2/user/model';
import { ImageAndroidDownload, ImageIOSDownload, ImageQRScan } from 'assets/images';
import { InputField, SelectOption } from 'components/FormControl';
import Translations from 'i18n/translation';
import { useState } from 'react';
import { Button, Col, Form, Image } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

export interface LoginFormProps {
    initialValues?: LoginRequest;
    onSubmit?: (formValues: LoginRequest) => void;
}

export default function LoginForm({ initialValues, onSubmit }: LoginFormProps) {
    const [, setError] = useState<string>('');
    const { t } = useTranslation();

    const schema = yup.object().shape({
        phoneNumber: yup.string().required(t(Translations.form.validate.required)),

        password: yup.string().required(t(Translations.form.validate.required)).min(6, t(Translations.form.password.length)).max(30, t(Translations.form.password.length)),
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<LoginRequest>({
        defaultValues: initialValues,
        resolver: yupResolver(schema),
    });

    const handleFormSubmit = async (formValues: LoginRequest) => {
        try {
            setError('');
            await onSubmit?.(formValues);
        } catch (error: any) {
            setError(error.message);
        }
    };
    const loadOptions = (inputValue: string, callback: (options: SelectOption[]) => void) => {
        console.log('load nef');

        setTimeout(() => {
            callback([
                { label: '111', value: 'name2' },
                { label: '222', value: 'name' },
            ]);
        }, 1000);
    };
    return (
        <>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <InputField xs={12} name="phoneNumber" control={control} label={t(Translations.form.username.name)} placeholder={t(Translations.form.username.placeholder)} />
                <InputField xs={12} type="password" name="password" control={control} label={t(Translations.form.password.name)} placeholder={t(Translations.form.password.placeholder)} />

                <Col lg="12" className="d-flex justify-content-between">
                    <Form.Check className="form-check mb-3">
                        <Form.Check.Input type="checkbox" className="text-primary" id="customCheck1" />
                        <Form.Check.Label htmlFor="customCheck1">{t(Translations.pages.signin.rememberMe)}</Form.Check.Label>
                    </Form.Check>
                    <Link to="#">{t(Translations.pages.signin.forgotPassword)}</Link>
                </Col>

                <div className="d-flex justify-content-center pt-3 pb-3">
                    <Button className="d-flex align-items-center" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <Spinner animation="border" className="me-3" variant="danger" />}
                        {t('common.login')}
                    </Button>
                </div>
            </form>

            <div className="my-3 d-flex justify-content-end">
                <div>
                    <p className="mb-1 text-success fw-bold fs-6 text-end"> {t(Translations.common.download)}</p>
                    <div className="d-flex gap-2">
                        <Image className="d-none d-lg-block" width={100} height={95} src={ImageQRScan} />
                        <div className="d-flex gap-2 flex-lg-column justify-content-lg-between">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.msa.sop" rel="noreferrer">
                                <Image width={122} src={ImageAndroidDownload} />
                            </a>
                            <a target="_blank" href="https://apps.apple.com/vn/app/hsse/id1557474738" rel="noreferrer">
                                <Image width={122} src={ImageIOSDownload} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
