import { AddButton, AddButtonNavigate } from 'components/Button';
import { Card, FancyTable } from 'components/shared';
import { Button, CardBody, CardHeader, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowClockwise } from 'react-bootstrap-icons';
import * as yup from 'yup';
import { t } from 'i18next';
import Translations from 'i18n/translation';
import userApi from 'api/v2/user/userApi';
import { UserFilterRequest, UserResponse } from 'api/v2/user/model';
import { PaginationBase, PaginationBaseRequest, defaultPaginationRequest } from 'api/models/common';
import { ColumnType, PaginationTable } from 'components/shared/PaginationTable';
import { ActionButtons2 } from 'components/shared/ActionButtons';
import { avataUserDefault } from 'constants/common';
import Routers from 'router/const/routers';
import { ActionEnum } from 'enum';
import { useNavigate } from 'react-router-dom';
import ImageAvatar from 'components/bootstrap/ImageAvatar';
import { lang } from 'i18n/lang';
import { useAuth } from 'providers/contexts';
import { formatDateTime } from 'helper';

const UserPage = () => {
    const { userPms } = useAuth();
    const [filter, setFilter] = useState<UserFilterRequest>({
        ...defaultPaginationRequest,
        companyId: undefined,
        areaId: undefined,
    });
    const [data, setData] = useState<PaginationBase<any>>();
    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData(filter);
    }, []);

    const onDelete = async (id: string) => {
        userApi.delete(id).then(() => {
            console.log('vao delete');
            handleFetchData(filter);
        });
    };
    const onResetPassword = (id: string) => {
        userApi.resetPassword(id);
    };
    const handleFetchData = async (page: PaginationBaseRequest) => {
        userApi
            .getPagination({
                ...filter,
                keySearch: page.keySearch,
                currentPage: page.currentPage,
                rowsPerPage: page.rowsPerPage,
            })
            .then((pagination: PaginationBase<UserResponse>) => {
                var dataTable = pagination.datas?.map((n) => {
                    return {
                        ...n,
                        createdTime: formatDateTime(n.createdTime),
                        logoImg: <ImageAvatar path={n.avatar ?? avataUserDefault} />,
                        action: (
                            <ActionButtons2
                                id={n.id}
                                editPms={userPms.user.admin}
                                deletePms={userPms.user.admin}
                                onDelete={onDelete}
                                editAction={() => {
                                    navigate(`${Routers.user.action}?type=${ActionEnum.Update}&id=${n.id}`);
                                }}
                                customActions={
                                    userPms.user.admin && (
                                        <OverlayTrigger overlay={<Tooltip>{'Reset password'}</Tooltip>} placement="top">
                                            <Button variant="icon btn-sm btn-warning rounded" onClick={() => onResetPassword(n.id)}>
                                                <ArrowClockwise />
                                            </Button>
                                        </OverlayTrigger>
                                    )
                                }
                            />
                        ), // replace 'value' with the actual value you want to set
                    };
                });
                var newData = { ...pagination, datas: dataTable };
                setData(newData);
                setFilter(page);
            })
            .catch((n) => {});
    };

    const cols: ColumnType[] = useMemo(
        () => [
            {
                field: 'logoImg',
                title: '',
            },
            {
                field: 'fullName',
                title: t(Translations.form.fullName.name),
            },
            {
                field: 'cardNumber',
                title: t(Translations.sec.dkshNumber),
            },
            {
                field: 'phoneNumber',
                title: t(Translations.form.phone.name),
            },
            {
                field: 'email',
                title: t(Translations.form.email.name),
            },
            {
                field: 'roleName',
                title: t(Translations.common.role),
            },
            {
                field: 'title',
                title: t(Translations.form.title.name),
            },
            {
                field: 'createdTime',
                title: lang.text(Translations.common.createdTime),
            },
            {
                field: 'createdUserName',
                title: lang.text(Translations.common.createdBy),
            },
            {
                field: 'action',
                title: '',
            },
        ],
        []
    );
    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-end">
                        <AddButtonNavigate url={Routers.user.action} isRender={userPms.supperAdmin || userPms.user.admin} />
                    </div>
                </CardBody>
            </Card>
            <PaginationTable onSearch={handleFetchData} onPaginate={handleFetchData} columns={cols} pagination={data} isStriped={true} wrapperClass="mt-5" />
        </Fragment>
    );
};

export default UserPage;
