import { BaseButton } from 'components/Button/BaseButton';
import { Logo } from 'components/shared';
import { Fragment, memo, useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { GridFill, List } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import Routers from 'router/const/routers';
import VerticalNav from '../../components/builder/verticalNav';
import { useSelector } from 'react-redux';
import * as SettingSelector from 'app/setting/selectors';

const MobileSideMenu = memo((props) => {
    const [show, setShow] = useState(false);
    const sidebarColor = useSelector(SettingSelector.sidebar_color);
    const sidebarType = useSelector(SettingSelector.sidebar_type); // array

    const router = useLocation();

    useEffect(() => {
        setShow(false);
    }, [router.pathname]);

    return (
        <Fragment>

            {/* Icon show menu */}
            <List style={{ color: 'white' }} className="mobile-menu-icon text-white hand" onClick={() => setShow(true)} />
            
            {/* List of vertical menu */}
            <Offcanvas show={show} onHide={() => setShow(false)} backdropClassName="menu-mobile-offcanvas">
                <Offcanvas.Header closeButton>
                    <div className="sidebar-header d-flex align-items-center justify-content-start">
                        <span className="navbar-brand d-flex w-100">
                            <Link to={Routers.root}>
                                <Logo />
                            </Link>
                            <h4 className="logo-title flex flex-col white-space-initial">
                                HSEVN <br />
                                {/* <small>Health Safety Security and Eviroment</small> */}
                            </h4>
                        </span>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={` ${sidebarColor} ${sidebarType.join(' ')} sidebar-base  `}>
                        <div className="pt-0 sidebar-body data-scrollbar" data-scroll="1" id="my-scrollbar">
                            {/* sidebar-list class to be added after replace css */}
                            <div className="sidebar-list navbar-collapse" id="sidebar">
                                <VerticalNav />
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Fragment>
    );
});

MobileSideMenu.displayName = 'MobileSideMenu';
export default MobileSideMenu;
